let BASE_URL, API_GRAPH_URL;
let CLEVERTAP_ACC;
let API_S3_UPLOAD_URL = "https://wl-api-1.staging.classplus.co";

if (process.env.REACT_APP_BUILD_ENV == "development") {
  BASE_URL = "https://apip.classplusapp.com";
  // API_GRAPH_URL = "https://api.classplusapp.com/cams/graph-api";
  API_GRAPH_URL = "https://graph-api.pp.staging.classplus.co/cams/graph-api";
  CLEVERTAP_ACC = "KKW-4W8-K75Z";
} else {
  if (process.env.REACT_APP_BUILD_ENV == "staging") {
    BASE_URL = "http://dev-testing.classplus.co";
    API_GRAPH_URL = "https://graph-api.staging.classplus.co/cams/graph-api";
    CLEVERTAP_ACC = "KKW-4W8-K75Z";
  }

  if (process.env.REACT_APP_BUILD_ENV == "preprod") {
    BASE_URL = "https://apip.classplusapp.com";
    API_GRAPH_URL = "https://graph-api.pp.staging.classplus.co/cams/graph-api";
    CLEVERTAP_ACC = "848-64W-675Z";
  } else if (process.env.REACT_APP_BUILD_ENV == "production") {
    BASE_URL = "https://api.classplusapp.com";
    API_GRAPH_URL = "https://api.classplusapp.com/cams/graph-api";
    CLEVERTAP_ACC = "4RW-7K5-575Z";
  }
}

export default { BASE_URL, API_GRAPH_URL, CLEVERTAP_ACC, API_S3_UPLOAD_URL };
