import React from "react";
import classes from "./WhatsIncluded.module.css";
import rightTikMobile from "../../images/WhatsIncluded/rightTikMobile.svg";
// import DurationMobile from "../../images/CourseDuration/DurationMobile.svg"

class WhatsIncluded extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      WhatsIncludedData: [
        { content: "Interactive learning with live classes" },
        { content: "Doubts resolved on chat" },
        { content: "Download content for offline access" },
        { content: "Learning at your own pace" },
      ],
    };
  }

  componentDidMount() {}

  render() {
    let { WhatsIncludedData, courseDuration } = this.props;

    return (
      <div
        className={classes.WhatsIncluded}
        style={{ borderTop: !courseDuration ? "1px solid #e5e5e5" : "none" }}
      >
        <div className={classes.WhatsIncludedHeading}>What’s included</div>
        <div className={classes.WhatsIncludedTextList}>
          {WhatsIncludedData.map((data, index) => {
            return (
              <div className={classes.WhatsIncludedTikText}>
                <img src={rightTikMobile} alt="" />
                <div className={classes.WhatsIncludedText}>{data.text}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default WhatsIncluded;
