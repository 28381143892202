import React from 'react';
import classes from './AboutCourseCreator.module.css';
import creatorImgMobile from '../../images/AboutCourseCreator/creatorImgMobile.svg';
import bookImgMobile from '../../images/AboutCourseCreator/bookImgMobile.svg';
import usersImg from '../../images/AboutCourseCreator/usersImg.svg';
import CustomUser from '../../UI/CustomUser/CustomUser';
class AboutCourseCreator extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {}

	render() {
		const { courseCreatorDetails } = this.props;
		return (
			<div className={classes.AboutCourseCreator} ref={this.props.reference}>
				<div className={classes.AboutCourseCreatorHeading}>{courseCreatorDetails.heading}</div>
				<div className={classes.AboutCourseCreatorImageTextGroup}>
					<div className={classes.AboutCourseCreatorImage}>
						<CustomUser
							username={courseCreatorDetails && courseCreatorDetails.tutorName}
							userImage={courseCreatorDetails && courseCreatorDetails.imageUrl}
						/>
						{/* <img src={courseCreatorDetails.imageUrl} alt="" style={{ height: '100%', width: '100%' }} /> */}
					</div>
					<div className={classes.AboutCourseCreatorContentGroup}>
						<div className={classes.AboutCourseCreatorName}>{courseCreatorDetails.tutorName}</div>
						<div className={classes.AboutCourseCreatorSubText}>{courseCreatorDetails.orgName}</div>
						<div className={classes.AboutCourseCreatorImgTextGroup}>
							<img src={bookImgMobile} alt="" />
							<div className={classes.AboutCourseCreatorTextGroup}>
								<span style={{ fontWeight: 'bold' }}>
									{courseCreatorDetails.courses && courseCreatorDetails.courses.text}{' '}
								</span>
							</div>
						</div>
						<div className={classes.AboutCourseCreatorImgTextGroup}>
							<img src={usersImg} alt="" />
							<div className={classes.AboutCourseCreatorTextGroup}>
								<span style={{ fontWeight: 'bold' }}>
									{courseCreatorDetails.students && courseCreatorDetails.students.text}{' '}
								</span>
							</div>
						</div>
						<div className={classes.AboutCourseCreatorFooterHeading}>
							{courseCreatorDetails.tutorDescription}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AboutCourseCreator;
