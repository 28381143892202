import React from "react";
import classes from "./Input.module.css";
import { checkValidity } from "../utils";

export default function Input(props) {
  return (
    <div
      className={
        props.name == "Email ID*" || props.name == "Name*"
          ? classes.Row
          : classes.MobileRow
      }
    >
      {props.name == "Email ID*" || props.name == "Name*" ? (
        <p className={classes.Label}>{props.name}</p>
      ) : (
        ""
      )}
      <input
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        required
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className={classes.Input}
        disabled={
          (props.name == "Email ID*" && props.emailDisabled == true) ||
          (props.name == "Name*" && props.nameDisabled == true)
            ? "disabled"
            : ""
        }
      />
      {props.touched && !checkValidity(props.value, props.validations) && (
        <p
          style={{
            color: "red",
            fontSize: "small",
            margin: "2px 0 0 2px",
            paddingLeft: "5px",
          }}
        >
          {props.errorMessage}
        </p>
      )}
    </div>
  );
}
