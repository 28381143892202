import React, { Component } from "react";
import Accordian from "../../UI/Accordian/Accordian";
import classes from "./CourseListingPage.module.css";
import ContentList from "../ContentListCard/ContentList";
import PreviewCourse from "../PreviewCourse/PreviewCourse";
import back from "../../images/backBtn.svg";
import { withRouter } from "react-router-dom";
import Coupons from "../Coupons/Coupons";
class CourseListingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      freeContent,
      priceDetails,
      stateArray,
      completePageData,
      navFolderArr,
    } = this.props;
    return (
      <div className={classes.container}>
        {navFolderArr.length > 0 ? (
          ""
        ) : (
          <div
            className={classes.header}
            onClick={() => {
              this.props.history.push("/");
            }}
          >
            <div className={classes.backBtn}>
              <img src={back} alt="" style={{ marginRight: "8px" }} />
            </div>
            Course Contents
          </div>
        )}

        <div
          className={classes.bodyContainer}
          style={{ marginTop: navFolderArr.length > 0 ? "0" : "64px" }}
        >
          {freeContent && freeContent.length > 0 ? (
            navFolderArr.length > 0 ? (
              ""
            ) : (
              <PreviewCourse freeContent={freeContent} />
            )
          ) : (
            ""
          )}
          <ContentList
            isCloseFormModal={this.props.isCloseFormModal}
            closeFormModal={this.props.closeFormModal}
            isInsideContent={1}
            priceDetails={priceDetails}
            freeContent={freeContent}
            stateArray={stateArray}
            data={completePageData}
            selectedTab={0}
            navFolderPushed={this.props.handleNavFolderPushed}
            toggleBothModals={this.props.toggleBothModals}
            toggleCouponModalFromHome={this.props.toggleCouponModalFromHome}
            getUpdatedCouponsAndPriceDetails={
              this.props.getUpdatedCouponsAndPriceDetails
            }
            handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
            openFormModal={this.props.openFormModal}
            currentCouponCode={this.props.currentCouponCode}
            multipleCouponsCondition={this.props.multipleCouponsCondition}
          />
          <Coupons
            isCloseFormModal={this.props.isCloseFormModal}
            closeFormModal={this.props.closeFormModal}
            studentCouponList={this.props.studentCouponList}
            triggerFormModal={this.props.triggerFormModal}
            stateArray={stateArray}
            completePageData={completePageData}
            priceDetails={priceDetails}
            getUpdatedCouponsAndPriceDetails={
              this.props.getUpdatedCouponsAndPriceDetails
            }
            handleViewAllFromForm={this.props.handleViewAllFromForm}
            isHideCouponDetails={this.props.isHideCouponDetails}
            toggleBothModals={this.props.toggleBothModals}
            toggleFormModal={this.props.toggleFormModal}
            toggleCouponModalFromHome={this.props.toggleCouponModalFromHome}
            handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
            openFormModal={this.props.openFormModal}
            notToShowCoupons={true}
            setCouponAppliedSuccess={this.props.setCouponAppliedSuccess}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(CourseListingPage);
