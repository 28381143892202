import React from "react";
import classes from "./MainPanel.module.css";
import maxresdefault from "../images/maxresdefault.svg";
import tag from "../images/tag.svg";
import headMobile from "../images/headMobile.svg";
import course from "../images/play.svg";
import test from "../images/test.svg";
import file from "../images/file.svg";
import play from "../images/play.svg";
import copy from "../images/copy.svg";
// import Accordian from '../Accordian/Accordian';
import TopViewDesktop from "../components/TopViewDesktop/TopViewDesktop";

import Tabview from "../components/Tabview/Tabview";
const scrollToDiv = (ref) => {
  if (ref.current) window.scrollTo(0, ref.current.offsetTop);
};
// const el1 = useRef();
// const el2 = useRef();

class MainPanel extends React.Component {
  // let description = "Securing a Bank Job and getting placed in the Banking sector has been one of the most sought after career streams for more than a decade now. Lakhs of aspirants appear for Bank Exams like IBPS PO, IBPS Clerk, IBPS RRB, SBI PO, RBI Grade etc. to get placed in one of the reputed banks of the country every year.These bank exams are conducted at national level throughout the year. Candidates who aspire for a banking career must be aware about all the Banking exams and start preparing. Here’s the exhaustive list of all the upcoming bank exams 2019Securing a Bank Job and getting placed in the Banking sector has been one of the most sought after career streams for more than a decade now. Lakhs of aspirants appear for Bank Exams like IBPS PO, IBPS Clerk, IBPS RRB, SBI PO, RBI Grade etc. to get placed in one of the reputed banks of the country every year.These bank exams are conducted at national level throughout the year. Candidates who aspire for a banking career must be aware about all the Banking exams and start preparing. Here’s the exhaustive list of all the upcoming bank exams 2019";
  constructor(props) {
    super(props);
    this.state = {};
    this.el1 = React.createRef();
    this.el2 = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    // let {
    // 	courseName,
    // 	description,
    // 	imageUrl,
    // 	price,
    // 	contentSummary,
    // 	validity,
    // 	orgName,
    // 	tags,
    // 	discount,
    // 	handlingFees,
    // 	totalPayableAmount
    // } = this.props.data.courseDetail;

    // let { videos, files, tests } = this.props.data.courseDetail.contentSummary;
    // console.log(videos);
    const {
      data,
      isTabClicked,
      handleTabClicked,
      similarCourseArr,
      freeContent,
      priceDetails,
      stateArray,
      isOverviewBtn,
      setCouponAppliedSuccess,
      couponAppliedSuccess,
      haveInvisibleCoupon,
      multipleCouponsCondition,
    } = this.props;

    // console.log(multipleCouponsCondition, "multipleCouponsConditionMAINPANEL");
    return (
      <div className={classes.container}>
        <TopViewDesktop
          courseDetails={data}
          reference={this.el1}
          click={() => scrollToDiv(this.el2)}
          isTabClicked={isTabClicked}
          handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
          toggleCouponModalFromHome={this.props.toggleCouponModalFromHome}
          openFormModal={this.props.openFormModal}
          currentCouponCode={this.props.currentCouponCode}
          multipleCouponsCondition={multipleCouponsCondition}
        />
        <Tabview
          isCloseFormModal={this.props.isCloseFormModal}
          isFormDetails={this.props.isFormDetails}
          closeFormModal={this.props.closeFormModal}
          similarCourseArr={similarCourseArr}
          courseDetails={data}
          reference={this.el2}
          handleTabClicked={handleTabClicked}
          freeContent={freeContent}
          priceDetails={priceDetails}
          stateArray={stateArray}
          data={data}
          isOverviewBtn={isOverviewBtn}
          studentCouponList={this.props.studentCouponList}
          triggerFormModal={this.props.triggerFormModal}
          getUpdatedCouponsAndPriceDetails={
            this.props.getUpdatedCouponsAndPriceDetails
          }
          handleViewAllFromForm={this.props.handleViewAllFromForm}
          isHideCouponDetails={this.props.isHideCouponDetails}
          toggleBothModals={this.props.toggleBothModals}
          toggleFormModal={this.props.toggleFormModal}
          toggleCouponModalFromHome={this.props.toggleCouponModalFromHome}
          handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
          removeCouponApplied={this.props.removeCouponApplied}
          openFormModal={this.props.openFormModal}
          setCouponAppliedSuccess={setCouponAppliedSuccess}
          couponAppliedSuccess={couponAppliedSuccess}
          currentCouponCode={this.props.currentCouponCode}
          haveInvisibleCoupon={haveInvisibleCoupon}
          multipleCouponsCondition={multipleCouponsCondition}
        />
      </div>
    );
  }
}

export default MainPanel;
