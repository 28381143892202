import React from 'react';
import SVGViewEye from './images/SVGViewEye.svg';
import SVGClock from './images/clock.svg';

function RestrictionDetails({ item }) {
	if (item.isVideoRestricted == 1) {
		if (item.thresholdText == null || item.thresholdColor == null) {
			return (
				<div className="videoResDiv">
					{item.videoCountKey != null && (
						<div
							className="flexrow"
							style={{
								color: item.videoCountColor,
								alignItems: 'center',
								'margin-bottom': '8px',
								alignItems: 'flex-start'
							}}
						>
							<img
								src={SVGViewEye}
								style={{
									width: '16px',
									margin: '2px 3px 0 0'
								}}
							/>
							{item.videoCountKey}
						</div>
					)}
					{item.videoDurationKey != null && (
						<div
							className="flexrow "
							style={{
								// 'margin-left': item.videoCountKey != null ? '1rem' : '0',
								color: item.videoDurationColor,
								alignItems: 'flex-start'
							}}
						>
							<img
								src={SVGClock}
								style={{
									width: '16px',
									height: '12px',
									margin: '2px 3px 0 0'
								}}
							/>
							{item.videoDurationKey}
						</div>
					)}
				</div>
			);
		} else if (item.thresholdText != 'null' || item.thresholdColor != 'null') {
			return (
				<div className="flexrow">
					<div
						style={{
							color: item.thresholdColor,
							backgroundColor: '#8EF6A0',
							fontSize: '8px',
							padding: '1px 4px',
							borderRadius: '2px'
						}}
					>
						{item.thresholdText}
					</div>
				</div>
			);
		}
	}
	return '';
}

export default RestrictionDetails;
