import React, { Component } from 'react';
import classes from './CourseStats.module.css';
import eye from '../../images/topviewDesktop/eye.svg';
import star from '../../images/topviewDesktop/star.svg';
import users from '../../images/topviewDesktop/users.svg';
import video from '../../images/topviewDesktop/video.svg';
class CourseStats extends Component {
	constructor() {
		super();
		this.state = {
			data: [
				{
					img: eye,
					number: '1M+',
					text: 'Views'
				},
				{
					img: users,
					number: '1000+',
					text: 'Enrolled'
				},
				{
					img: video,
					number: 'Live',
					text: 'Classes'
				},
				{
					img: star,
					number: 'Recently',
					text: 'Updated'
				}
			]
		};
	}

	render() {
		let { data } = this.state;
		const { courseHighlights } = this.props;
		return (
			<div
				className={classes.container}
				style={{ justifyContent: courseHighlights.length < 4 ? "space-evenly" : "space-around" }}
			>
				{courseHighlights &&
					courseHighlights.map((dt, ind) => {
						let dtArray = dt.text.split(" ")
						let topText = dtArray[0];
						let bottomText = dtArray[1]
						return (
							<div className={classes.items}>
								<img src={dt.icon} alt="" className={classes.itemImg} />
								{/* {dt.number} <br /> */}
								{/*{dt.text}*/}
								<div className={classes.itemsTextContent}>
									<div>{topText}</div>
									<div>{bottomText}</div>
								</div>
							</div>
						);
					})}
			</div>
		);
	}
}
export default CourseStats;
