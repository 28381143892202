export function hashCode(string) {
    let hash = 0;
    string = string ? string : "";
    if (string.length === 0) return hash;
    for (let i = 0; i < string.length; i += 1) {
        const chr = string.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

function getShortenedSubstring(name, length, allowDots = true) {
    if (name) {
        return name.length < length
            ? name
            : `${name.substr(0, length)}${allowDots && "..."}`;
    }

    return "";
}

export const getParams = () => {
    const url = window.location.href;
    const params = {};
    const parser = document.createElement("a");
    parser.href = url;
    const query = parser.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
};

function getDurationFormat(duration) {
    let newDuration = duration.split(":");
    newDuration = newDuration.map(duration => {
        if (duration.length === 2) {
            return duration;
        }
        return `0${duration}`;
    });
    return newDuration.toString().replace(/,/g, ":");
}

function getDuration(duration) {
    let tempDuration = duration.replace("PT", "");
    let newDuration = "";
    if (tempDuration.includes("H")) {
        const arr = tempDuration.split("H");
        newDuration = `${newDuration + arr[0]}:`;
        tempDuration = arr[1];
    } else {
        newDuration += "00:";
    }
    if (tempDuration && tempDuration.includes("M")) {
        const arr = tempDuration.split("M");
        newDuration = `${newDuration + arr[0]}:`;
        tempDuration = arr[1];
    } else {
        newDuration += "00:";
    }
    if (tempDuration && tempDuration.includes("S")) {
        const arr = tempDuration.split("S");
        newDuration += arr[0];
    } else {
        newDuration += "00";
    }
    return getDurationFormat(newDuration);
}

export default {
    hashCode,
    getShortenedSubstring,
    getDuration,
    getDurationFormat
};
