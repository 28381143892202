import React, { Component } from "react";
import classes from "./CourseOverview.module.css";
import ContentSummary from "../ContentSummary/ContentSummary";
import buyNow from "../../images/courseOverview/buyNow.svg";
import stick from "../../images/courseOverview/stick.svg";
import courseCard from "../../images/courseOverview/courseCard.svg";
import shield from "../../images/courseOverview/shield.svg";
import Form from "../../Form/Form";
import play from "../../images/courseOverview/playButton.svg";
import share from "../../images/courseOverview/shareMobile.svg";
import CourseStats from "../CourseStats/CourseStats";
import MobileCarousel from "../MobileCarousel/MobileCarousel";
import Modal from "../../UI/Modal/Modal";
import FormatModal from "../../UI/FormatModal/FormatModal";
import fbImg from "../../images/fbImg.svg";
import twiiterImg from "../../images/twiiterImg.svg";
import whatsappImg from "../../images/whatsappImg.svg";
import ShareModal from "../../ShareModal/ShareModal";
import { withRouter } from "react-router-dom";
import NoCouponTag from "../../images/NoCouponTag.svg";

class CourseOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormDetails: false,
      isShareBtn: false,
      dynamicHeight: "100%",
    };
  }

  componentDidMount() {
    this.setState({ dynamicHeight: (this.imageRef.clientWidth * 7) / 10 });
  }

  handleFormDetails = () => {
    this.setState({ isFormDetails: false });
  };

  handleShareBtn = () => {
    this.setState({ isShareBtn: false });
  };

  render() {
    let { isFormDetails, isShareBtn } = this.state;
    const {
      data,
      courseHighlights,
      stateArray,
      priceDetails,
      showCouponsOnForm,
      multipleCouponsCondition,
    } = this.props;
    const { details, emblemList } = this.props.data;
    // console.log(stateArray,"+++ CourseOverview")
    let token = sessionStorage.getItem("token");
    let code = JSON.parse(sessionStorage.getItem("currentCouponCode"));

    return (
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <div className={classes.topSellingTag}>Top Selling</div>
          <img
            ref={(ele) => {
              this.imageRef = ele;
            }}
            src={
              details.imageUrl ||
              "https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/cards-icon/default_course.png"
            }
            alt=""
            className={classes.image}
            style={{ height: this.state.dynamicHeight }}
          />
          <button
            className={classes.preview}
            onClick={() => {
              this.props.history.push("/Preview");
            }}
          >
            <img src={play} alt="" style={{ marginRight: "8px" }} />
            Preview Course
          </button>
        </div>
        <div className={classes.mobileHeading}>
          <p style={{ margin: 0 }}>{details.name}</p>
          <button
            className={classes.shareBtn}
            onClick={() => {
              this.setState({ isShareBtn: true });
            }}
          >
            <img src={share} alt="" style={{ marginLeft: "12px" }} />
          </button>
        </div>

        <div className={classes.pricing}>
          <span className={classes.cost}>
            ₹ {data && data.priceDetails.coursePrice}
          </span>
          {data && data.priceDetails.discount > 0 ? (
            <>
              {" "}
              <span className={classes.discValue}>
                ₹ {data && data.priceDetails.price}
              </span>{" "}
              <span className={classes.discount}>
                {(
                  (data.priceDetails.discount / data.priceDetails.price) *
                  100
                ).toFixed(2)}
                % OFF
              </span>
            </>
          ) : (
            ""
          )}
        </div>

        {showCouponsOnForm ? (
          <div
            className={classes.CouponsAvailable}
            onClick={() => {
              this.setState({ isFormDetails: true }, () => {
                this.props.handleOverviewBtnClicked(true);
                // this.props.closeFormModal(false);
                this.props.toggleCouponModalFromHome();
              });
            }}
            style={{ cursor: "pointer" }}
          >
            <img src={NoCouponTag} alt="" />{" "}
            {multipleCouponsCondition == 1 || multipleCouponsCondition == 2
              ? "Apply Coupon Code"
              : "Apply Coupon Code"}
          </div>
        ) : (
          ""
        )}

        <div className={classes.courseStatsMobile}>
          <CourseStats courseHighlights={courseHighlights} />
        </div>
        <div className={classes.courseStatsMobile}>
          <MobileCarousel emblemList={emblemList} />
        </div>
        {emblemList &&
          emblemList.map((dt, ind) => {
            if (dt && dt.priceInfo) {
              return (
                <div className={classes.stickpoint}>
                  <img
                    src={dt.icon}
                    alt=""
                    style={{
                      marginRight: "8px",
                      height: "20px",
                      marginTop: "2px",
                    }}
                  />
                  {dt.text}
                </div>
              );
            }

            return "";
          })}
        <button
          className={classes.buyBtn}
          onClick={() => {
            this.setState({ isFormDetails: true }, () => {
              this.props.handleOverviewBtnClicked(true);
              this.props.closeFormModal(false);
              this.props.getUpdatedCouponsAndPriceDetails(
                token,
                code && code.code ? code && code.code : ""
              );
            });
          }}
        >
          <img src={buyNow} alt="" style={{ marginRight: "16px" }} />
          Buy Course Now
        </button>
        <div className={classes.secure}>
          <img src={shield} alt="" style={{ marginRight: "8px" }} />
          Secure payment with Razorpay
        </div>
        <div className={classes.contentSummaryDesktop}>
          <ContentSummary resources={data.resources} />
        </div>
        <Form
          isCloseFormModal={this.props.isCloseFormModal}
          closeFormModal={this.props.closeFormModal}
          isFormDetails={isFormDetails}
          handleFormDetails={this.handleFormDetails}
          data={this.props.data}
          stateArray={stateArray}
          priceDetails={priceDetails}
          handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
          toggleBothModals={this.props.toggleBothModals}
          toggleCouponModalFromHome={this.props.toggleCouponModalFromHome}
          getUpdatedCouponsAndPriceDetails={
            this.props.getUpdatedCouponsAndPriceDetails
          }
          removeCouponApplied={this.props.removeCouponApplied}
          currentCouponCode={code}
          setCouponAppliedSuccess={this.props.setCouponAppliedSuccess}
          couponAppliedSuccess={this.props.couponAppliedSuccess}
          multipleCouponsCondition={this.props.multipleCouponsCondition}
        />
        <ShareModal
          shareUrl={details.shareUrl}
          isShareBtn={isShareBtn}
          handleShareBtn={this.handleShareBtn}
        />
      </div>
    );
  }
}
export default withRouter(CourseOverview);
