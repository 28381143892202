export default `query ($token: String!, $uniqueId: String!){
  withAuth(token: $token ) {
    user {
      student {
        mapAnonymousCoupon(uniqueId :$uniqueId) {
          id
        }
      }
    }
  }
 }


`;
