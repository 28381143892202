export const RESOURCE_TYPES = {
    folder: 1,
    video: 2,
    document: 3,
    test: 4,
    batch: 5,
    image: 6, // just for frontend.. images at backend are still documents
  };

  export const RESOURCE_FILETYPES = {
    [RESOURCE_TYPES.document]: {
      ext: ["pdf", "PDF"],
      maxSize: 40000000, // 40 mb
    },
    [RESOURCE_TYPES.image]: {
      ext: ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"],
      maxSize: 40000000, //40 mb
    },
  };
  
  export const COURSE_SECTIONS = {
    OVERVIEW: "overview",
    CONTENT: "content",
    UPDATES: "updates",
    STUDENTS: "students",
    RESELLER_SETTINGS: "resellerSettings",
    ANNOUNCEMENTS: "announcements",
    DASHBOARD: 3,
    LIVE_CLASSES: "liveClasses",
  };