import React, { Component } from "react";
import classes from "./TopViewDesktop.module.css";
import shareButton from "../../images/topviewDesktop/shareButton.svg";
import sale from "../../images/topviewDesktop/sale.svg";
import purchase from "../../images/topviewDesktop/purchase.svg";
import CourseStats from "../CourseStats/CourseStats";
import Modal from "../../UI/Modal/Modal";
import FormatModal from "../../UI/FormatModal/FormatModal";
import fbImg from "../../images/fbImg.svg";
import twiiterImg from "../../images/twiiterImg.svg";
import whatsappImg from "../../images/whatsappImg.svg";
import ShareModal from "../../ShareModal/ShareModal";

class TopViewDesktop extends Component {
  constructor() {
    super();
    this.state = {
      isShareBtn: false,
    };
  }

  handleShareBtn = () => {
    this.setState({ isShareBtn: false });
  };

  checkBorderCondition = (data) => {
    let showBorder = false;

    if (data && data.length) {
      data.forEach((item) => {
        if (!item.priceInfo) {
          showBorder = true;
        }
      });
    }

    return showBorder;
  };

  render() {
    let { isShareBtn } = this.state;
    const {
      courseCreatorDetails,
      details,
      emblemList,
      highlights,
    } = this.props.courseDetails;

    let { isTabClicked } = this.props;

    let showBorder = this.checkBorderCondition(emblemList);

    return (
      <div
        className={classes.container}
        style={
          window.screen.width < 1000 ? { display: "none" } : { display: "flex" }
        }
      >
        <div className={classes.courseName}>
          {details.name}
          <button
            className={classes.shareButton}
            onClick={() => {
              this.setState({ isShareBtn: true });
            }}
          >
            <img
              src={shareButton}
              alt="share"
              style={{ marginRight: "10px" }}
            />
            Share
          </button>
        </div>

        <button
          className={classes.creator}
          onClick={this.props.click}
          ref={this.props.reference}
        >
          Created by &nbsp;
          <span className={classes.creatorName}>
            {" "}
            {courseCreatorDetails.tutorName}
          </span>
          &nbsp; | {courseCreatorDetails.orgName}
        </button>
        <React.Fragment
          style={showBorder ? { borderTop: "1px solid #E5E5E5" } : {}}
        >
          {emblemList &&
            emblemList.map((item) => {
              if (!item.priceInfo) {
                return (
                  <div className={classes.list}>
                    <img
                      src={item.icon}
                      alt="sales"
                      style={{ marginRight: "12px", width: "20px" }}
                    />
                    {item.text}
                  </div>
                );
              }

              return "";
            })}
        </React.Fragment>

        {highlights && highlights.length > 0 ? (
          <div className={showBorder ? classes.showBorderClass : ""}>
            <CourseStats courseHighlights={highlights} />
          </div>
        ) : (
          ""
        )}
        <ShareModal
          shareUrl={details.shareUrl}
          isShareBtn={isShareBtn}
          handleShareBtn={this.handleShareBtn}
        />
      </div>
    );
  }
}
export default TopViewDesktop;
