export function checkValidity(value, validations) {
  let isValid = true;

  if (validations.required) {
    isValid = isValid && !!value;
  }
  if (validations.pattern) {
    isValid = isValid && validations.pattern.test(value);
  }

  return isValid;
}

export function getQuery() {
  let b = window.location.search
    .slice(1)
    .split("&")
    .map((qStr) => qStr.split("="))
    .reduce((acc, inc) => {
      acc[inc[0]] = inc[1];
      return acc;
    }, {});

  return b;
}

export function getShortenedSubstring(name, length, allowDots = true) {
  if (name) {
    return name.length < length
      ? name
      : `${name.substr(0, length)}${allowDots && "..."}`;
  }

  return "";
}

export function makeid(length) {
  if (!length) return "";
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
