export default `query($token: String!,$code: String,$uniqueId: String!){
  withoutAuth {
    anonymousUser(token:$token){
      inVisibleCouponCount
      anonymousCourseOverview(uniqueId:$uniqueId, code:$code) {
      coupon {
        id
          code
          name
          amount
          discountType
          couponType
          maxAmount
          totalLimit
          startDateTime
          endDateTime
          courseIds
          isApplicableToAllCourses
      }
      course {
        id
  			handlingFactor
  			ifFeeHandledByTutor
  			redeemableAmount
  			discount
  			price
  			name
      } 
      }
    }
  }
}

mapper <safejs-

const inVisibleCouponCount = data.withoutAuth.anonymousUser.inVisibleCouponCount;
const hashCoupon = data.withoutAuth.anonymousUser.anonymousCourseOverview.coupon !== null;
let coupon = data.withoutAuth.anonymousUser.anonymousCourseOverview.coupon;
if (coupon)  coupon = [coupon]
data = courseOverview(coupon, [data.withoutAuth.anonymousUser.anonymousCourseOverview.course], 0, true, hashCoupon, "primaryCourseId");
data.inVisibleCouponCount = inVisibleCouponCount;
-js>
`;
