import React from 'react';
import classes from './StudentViewedDesktop.module.css';
import courseImgDesktop1 from '../../images/StudentsViewed/courseImgDesktop1.svg';
import courseImgDesktop2 from '../../images/StudentsViewed/courseImgDesktop2.svg';
// import Slider from 'react-slick';
import Carousel from '../../UI/Carousel/Carousel';
import { withRouter } from 'react-router-dom';
class StudentViewedDesktop extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			carouselData: [
				{
					image: courseImgDesktop1,
					name: 'SSC CGL Course 2018 : For All Government Job Applicatnts',
					amount: '₹ 2,999/-',
					dicount: '₹ 3,999/-',
					offer: '25% OFF'
				},
				{
					image: courseImgDesktop2,
					name: 'SSC CGL Course 2018 : For All Government Job Applicatnts',
					amount: '₹ 2,999/-',
					dicount: '₹ 3,999/-',
					offer: '25% OFF'
				},
				{
					image: courseImgDesktop1,
					name: 'SSC CGL Course 2018 : For All Government Job Applicatnts',
					amount: '₹ 2,999/-',
					dicount: '₹ 3,999/-',
					offer: '25% OFF'
				},
				{
					image: courseImgDesktop2,
					name: 'SSC CGL Course 2018 : For All Government Job Applicatnts',
					amount: '₹ 2,999/-',
					dicount: '₹ 3,999/-',
					offer: '25% OFF'
				}
			]
		};
	}

	componentDidMount() { }

	handleCourseOpen = (item) => {
        window.open(item.singlePaymentLink)
    }

	render() {
		let { carouselData } = this.state;
		const { similarCourseArr } = this.props

		// const settings = {
		// 	dots: false,
		// 	infinite: true,
		// 	slidesToShow: 3,
		// 	slidesToScroll: 1,
		// 	autoplay: false,
		// 	speed: 1000,
		// 	autoplaySpeed: 2000,
		// 	cssEase: 'linear',
		// 	pauseOnHover: true,
		// 	arrows: true
		// };

		return (
			<div className={classes.StudentViewedDesktop}>
				<div className={classes.StudentViewedHead}>
					<div className={classes.StudentViewedHeading}>Students also viewed</div>
					<button
						className={classes.StudentViewedbtn}
						onClick={() => {
							this.props.history.push('/MoreCourses');
						}}
					>
						See more courses
					</button>
				</div>
				<div className={classes.StudentViewedCourseList}>
					{/* <Slider {...settings}> */}
					<Carousel
						// loading={props.loading}
						// listStyle={classes.marginZero}
						elements={similarCourseArr.slice(0,3).map((carouselData, index) => {
							return (
								<div className={classes.StudentViewedCourse} key={index} onClick={() => this.handleCourseOpen(carouselData)}>
									<div className={classes.StudentViewedCourseImg}>
										<img src={carouselData.imageUrl || 'https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/cards-icon/default_course.png'} alt="" style={{ height: "100%", width: "100%" }} />
									</div>
									<div className={classes.StudentViewedCourseContent}>
										<div className={classes.StudentViewedCourseContentRow1}>
											{carouselData.name}
										</div>
										<div className={classes.StudentViewedCourseContentRow2}>
											<div className={classes.StudentViewedCoursePrice}>
												{carouselData.finalPrice}
											</div>
										{carouselData.discount > 0 ? <> <div className={classes.StudentViewedCourseDiscount}>
												{carouselData.price}
											</div>
											<div className={classes.StudentViewedCourseOffer}>{((carouselData.discount / carouselData.price) * 100).toFixed(2)}% OFF</div> </> : ''}
										</div>
									</div>
								</div>
							);
						})}
						dotsDisabled={true}
						width="100%"
						elementWidth={window.screen.width}
						activeSlide={0}
					/>

					{/* </Slider> */}
				</div>
			</div>
		);
	}
}

export default withRouter(StudentViewedDesktop);
