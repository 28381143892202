import React, { Component } from "react";
import classes from "./ContentLockedModal.module.css";
import Modal from "../UI/Modal/Modal";
import FormatModal from "../UI/FormatModal/FormatModal";
import LockIconModal from "../images/LockIconModal.svg";
import shoppingbagIcon from "../images/shoppingbagIcon.svg";
import razorPayIcon from "../images/razorPayIcon.svg";
import Form from "../Form/Form";
import lockGif from "../images/lockGif.gif";
import lockGifDesktop from "../images/lockGifDesktop.gif";
class ContentLockedModal extends Component {
  constructor() {
    super();
    this.state = {
      isFormDetails: false,
      // isCloseLockedContentFormModal: false
    };
  }
  handleFormDetails = () => {
    this.setState({ isFormDetails: false });
  };
  // closeLockedContentFormModal = (isCloseLockedContentModal) => {
  // 	// let { isCloseFormModal } = this.state
  // 	this.setState({ isCloseLockedContentFormModal: isCloseLockedContentModal })
  // }

  setCloseModal = () => {
    this.setState({ isFormDetails: true });
    this.props.handleContentLockedModal();
    this.props.openFormModal();
    this.props.handleOverviewBtnClicked(true);
  };

  render() {
    let { isContentLocked, priceDetails, stateArray, data } = this.props;
    let { isFormDetails, isCloseLockedContentFormModal } = this.state;
    console.log(priceDetails, "++++priceDetails");

    let priceDetailsLength = priceDetails.length;
    return (
      <Modal
        toggle={this.props.handleContentLockedModal}
        hidden={!isContentLocked}
      >
        <FormatModal>
          <div className={classes.ContentLockedModal}>
            {window.screen.width < 700 && (
              <div className={classes.ContentLockedModalHeading}>
                Buy Course
              </div>
            )}
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {window.screen.width < 700 ? (
                <img
                  src={
                    "https://cp-assets-public.s3.ap-south-1.amazonaws.com/classplus-websites/common/images/lockGif.gif"
                  }
                  alt=""
                />
              ) : (
                <img
                  src={
                    "https://cp-assets-public.s3.ap-south-1.amazonaws.com/classplus-websites/common/images/lockGif.gif"
                  }
                  alt=""
                />
              )}
            </div>
            <div className={classes.ContentLockedModalHeadText}>
              Buy now to start learning!
            </div>
            <div className={classes.ContentLockedModalSubText}>
              Get access to course contents and learn from the comfort of any of
              your devices
            </div>
            {window.screen.width > 700 ? (
              <div>
                <button
                  className={classes.ContentLockedModalButton}
                  onClick={this.setCloseModal}
                >
                  <img
                    src={shoppingbagIcon}
                    alt=""
                    style={{ marginRight: "16px" }}
                  />
                  Buy Course Now
                </button>
                <div className={classes.ContentLockedModalSecurePay}>
                  <img src={razorPayIcon} alt="" />
                  <label>Secure payment with Razorpay</label>
                </div>
              </div>
            ) : (
              <div>
                <div className={classes.ContentLockedModalSecurePay}>
                  <img src={razorPayIcon} alt="" />
                  <label>Secure payment with Razorpay</label>
                </div>
                <button
                  className={classes.ContentLockedModalButton}
                  onClick={this.setCloseModal}
                >
                  {/*<img src={shoppingbagIcon} alt="" style={{ marginRight: "16px" }} />*/}
                  Buy now for
                  {priceDetails &&
                    priceDetails[priceDetailsLength - 1] &&
                    priceDetails[priceDetailsLength - 1].value}
                </button>
              </div>
            )}
            {window.innerWidth < 700 ? (
              <Form
                isCloseFormModal={this.props.isCloseFormModal}
                closeFormModal={this.props.closeFormModal}
                stateArray={stateArray}
                isFormDetails={this.state.isFormDetails}
                handleFormDetails={this.handleFormDetails}
                data={data}
                priceDetails={priceDetails}
                toggleBothModals={this.props.toggleBothModals}
                getUpdatedCouponsAndPriceDetails={
                  this.props.getUpdatedCouponsAndPriceDetails
                }
                toggleCouponModalFromHome={this.props.toggleCouponModalFromHome}
                removeCouponApplied={this.props.removeCouponApplied}
                handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
                currentCouponCode={this.props.currentCouponCode}
                multipleCouponsCondition={this.props.multipleCouponsCondition}
              />
            ) : (
              ""
            )}
          </div>
        </FormatModal>
      </Modal>
    );
  }
}
export default ContentLockedModal;
