export default `query($token: String!,$limit: Int, $offset: Int){
  withoutAuth {
    anonymousUser(token:$token){
      inVisibleCouponCount
      coupons(limit:$limit, offset:$offset){
      id
        code
        isActive
        isDeleted
        totalLimit
        amount
        name
        couponType
        discountType
        endDateTime
        startDateTime
        isExpired
        isExhausted
        endDateTime
        maxAmount
    }
      
    }
  }
}



mapper <safejs-

const totalCount = data.coupons_total;
const inVisibleCouponCount = data.withoutAuth.anonymousUser.inVisibleCouponCount;
data = couponApplyAndRemove(data.withoutAuth.anonymousUser.coupons, null, null, totalCount);
data.inVisibleCouponCount = inVisibleCouponCount;


-js>`;
