import React from 'react';
import classes from './CourseDuration.module.css';
import DurationMobile from '../../images/CourseDuration/DurationMobile.svg';

class CourseDuration extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {}

	render() {
		const { courseDuration } = this.props;

		return (
			<div className={classes.CourseDuration}>
				<div className={classes.CourseDurationHeading}>{courseDuration.heading}</div>
				<div className={classes.CourseDurationImgContent}>
					<div className={classes.CourseDurationImg}>
						<img src={courseDuration.icon} alt="" />
					</div>
					<div className={classes.CourseDurationContent}>
						<div className={classes.CourseDurationContentRow1}>{courseDuration.text}</div>
						<div className={classes.CourseDurationContentRow2}>{courseDuration.subText}</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CourseDuration;
