import React, { Component } from 'react';
import classes from './ContentSummary.module.css';
import book from '../../images/courseOverview/book.svg';
import vid from '../../images/courseOverview/vid.svg';
import file from '../../images/courseOverview/file.svg';
class ContentSummary extends Component {
	constructor() {
		super();
		this.state = {
			data: [
				{
					img: vid,
					number: 'videos',
					text: 'Videos'
				},
				{
					img: book,
					number: 'files',
					text: 'Files'
				},
				{
					img: file,
					number: 'tests',
					text: 'Tests'
				}
			]
		};
	}

	render() {
		let { data } = this.state;
		const { resources } = this.props;
		return (
			<div className={classes.container}>
				<p className={classes.heading}>Content summary</p>
				<div className={classes.content}>
					{data.map((dt, ind) => {
						if (resources && resources[dt.number]) {
							return (
								<div className={classes.items}>
									<img src={dt.img} alt="" className={classes.itemsImage} />
									<div className={classes.itemTexts}>
										<div style={{ fontWeight: 'bold' }}>{resources && resources[dt.number]}</div>
										<div>{dt.text}</div>
									</div>
								</div>
							);
						}

						return '';
					})}
				</div>
			</div>
		);
	}
}
export default ContentSummary;
