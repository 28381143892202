import React, { Component } from "react";
import classes from "./Tabview.module.css";
import CourseDuration from "../CourseDuration/CourseDuration";
import WhatsIncluded from "../WhatsIncluded/WhatsIncluded";
import AboutCourseCreator from "../AboutCourseCreator/AboutCourseCreator";
import StudentViewedMobileView from "../StudentsViewed/StudentsViewed";
import StudentViewedDesktopView from "../StudentsViewedDesktop/StudentViewedDesktop";
import ContentList from "../ContentListCard/ContentList";
import Description from "../Description/Description";
import PreviewCourse from "../PreviewCourse/PreviewCourse";
import ContentSummary from "../ContentSummary/ContentSummary";
import Coupons from "../Coupons/Coupons";

class Tabview extends Component {
  constructor() {
    super();
    this.state = {
      selectedTab: 0,
      isViewAllContent: false,
      navFolderArr: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isViewAllContent != this.state.isViewAllContent &&
      this.state.isViewAllContent == true
    ) {
      this.setState({ selectedTab: 1 });
    }
  }

  handelTabClick = (tab) => {
    this.setState({ selectedTab: tab, isViewAllContent: false });
    this.props.handleTabClicked(true);
    // if (this.state.selectedTab == 0) {
    // 	this.setState({ selectedTab: 1 });
    // } else {
    // 	this.setState({ selectedTab: 0 });
    // }
  };

  handleViewAllPreviewContent = (isClicked) => {
    this.setState({ isViewAllContent: isClicked });
  };

  handleNavFolderPushed = (data) => {
    this.setState({
      navFolderArr: data,
    });
  };

  render() {
    let { selectedTab, isViewAllContent, isClicked, navFolderArr } = this.state;
    const {
      courseDetails,
      similarCourseArr,
      freeContent,
      priceDetails,
      stateArray,
      data,
      isOverviewBtn,
      setCouponAppliedSuccess,
      couponAppliedSuccess,
      haveInvisibleCoupon,
      multipleCouponsCondition,
    } = this.props;

    // console.log(multipleCouponsCondition, "multipleCouponsConditionTABVIEW");
    return (
      <React.Fragment>
        <div className={classes.mobileContainer}>
          <Description
            description={
              courseDetails &&
              courseDetails.details &&
              courseDetails.details.description
            }
          />

          <ContentSummary resources={courseDetails.resources} />
          {freeContent && freeContent.length > 0 ? (
            <PreviewCourse
              freeContent={freeContent}
              freeContentSection={courseDetails.freeContent}
              handleViewAllPreviewContent={this.handleViewAllPreviewContent}
            />
          ) : (
            <ContentList
              isCloseFormModal={this.props.isCloseFormModal}
              closeFormModal={this.props.closeFormModal}
              handleViewAllPreviewContent={this.handleViewAllPreviewContent}
              priceDetails={priceDetails}
              stateArray={stateArray}
              data={data}
              viaMobileOverview={1}
              handleTabClick={this.handelTabClick}
              freeContent={freeContent}
              selectedTab={selectedTab}
              navFolderPushed={this.handleNavFolderPushed}
              toggleBothModals={this.props.toggleBothModals}
              toggleCouponModalFromHome={this.props.toggleCouponModalFromHome}
              removeCouponApplied={this.props.removeCouponApplied}
              getUpdatedCouponsAndPriceDetails={
                this.props.getUpdatedCouponsAndPriceDetails
              }
              handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
              openFormModal={this.props.openFormModal}
              currentCouponCode={this.props.currentCouponCode}
              haveInvisibleCoupon={haveInvisibleCoupon}
              multipleCouponsCondition={multipleCouponsCondition}
            />
          )}
          {courseDetails.courseDuration && (
            <CourseDuration courseDuration={courseDetails.courseDuration} />
          )}
          <WhatsIncluded
            courseDuration={courseDetails.courseDuration}
            WhatsIncludedData={courseDetails && courseDetails.otherFeatures}
          />

          <Coupons
            setCouponAppliedSuccess={setCouponAppliedSuccess}
            isCloseFormModal={this.props.isCloseFormModal}
            closeFormModal={this.props.closeFormModal}
            studentCouponList={this.props.studentCouponList}
            triggerFormModal={this.props.triggerFormModal}
            stateArray={stateArray}
            completePageData={courseDetails}
            priceDetails={priceDetails}
            getUpdatedCouponsAndPriceDetails={
              this.props.getUpdatedCouponsAndPriceDetails
            }
            handleViewAllFromForm={this.props.handleViewAllFromForm}
            isHideCouponDetails={this.props.isHideCouponDetails}
            toggleBothModals={this.props.toggleBothModals}
            toggleFormModal={this.props.toggleFormModal}
            toggleCouponModalFromHome={this.props.toggleCouponModalFromHome}
            handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
            openFormModal={this.props.openFormModal}
            haveInvisibleCoupon={haveInvisibleCoupon}
            multipleCouponsCondition={multipleCouponsCondition}
          />
          <div
            style={{
              display: "block",
              width: "100%",
              borderBottom: "8px solid #E5E5E5",
              margin: "0 auto",
            }}
          ></div>
          <AboutCourseCreator
            courseCreatorDetails={courseDetails.courseCreatorDetails}
          />
          {/* {window.screen.width < 700 ? <StudentViewedMobileView /> : <StudentViewedDesktopView />} */}
          {similarCourseArr && similarCourseArr.length > 0 ? (
            <StudentViewedDesktopView similarCourseArr={similarCourseArr} />
          ) : (
            ""
          )}
          {similarCourseArr && similarCourseArr.length > 0 ? (
            <StudentViewedMobileView similarCourseArr={similarCourseArr} />
          ) : (
            ""
          )}
        </div>
        <div className={classes.desktopContainer}>
          <div
            className={classes.header}
            style={isOverviewBtn ? { zIndex: "0" } : { zIndex: "4" }}
          >
            <div className={classes.cheatBar}></div>
            <button
              className={
                selectedTab == 0 ? classes.selected : classes.unselected
              }
              onClick={() => this.handelTabClick(0)}
            >
              About Course
            </button>
            <button
              className={
                selectedTab == 1 ? classes.selected : classes.unselected
              }
              onClick={() => this.handelTabClick(1)}
            >
              Course Content
            </button>
          </div>
          <div className={classes.content}>
            {selectedTab == 0 ? (
              <div style={{ width: "100%" }}>
                <Description
                  description={
                    courseDetails &&
                    courseDetails.details &&
                    courseDetails.details.description
                  }
                />
                {freeContent && freeContent.length > 0 ? (
                  <PreviewCourse
                    handleViewAllPreviewContent={
                      this.handleViewAllPreviewContent
                    }
                    freeContent={freeContent}
                  />
                ) : (
                  <ContentList
                    isCloseFormModal={this.props.isCloseFormModal}
                    closeFormModal={this.props.closeFormModal}
                    handleViewAllPreviewContent={
                      this.handleViewAllPreviewContent
                    }
                    priceDetails={priceDetails}
                    stateArray={stateArray}
                    viaDesktopOverview={1}
                    handleTabClick={this.handelTabClick}
                    freeContent={freeContent}
                    selectedTab={selectedTab}
                    navFolderPushed={this.handleNavFolderPushed}
                    toggleBothModals={this.props.toggleBothModals}
                    toggleCouponModalFromHome={
                      this.props.toggleCouponModalFromHome
                    }
                    removeCouponApplied={this.props.removeCouponApplied}
                    getUpdatedCouponsAndPriceDetails={
                      this.props.getUpdatedCouponsAndPriceDetails
                    }
                    handleOverviewBtnClicked={
                      this.props.handleOverviewBtnClicked
                    }
                    openFormModal={this.props.openFormModal}
                    currentCouponCode={this.props.currentCouponCode}
                    haveInvisibleCoupon={haveInvisibleCoupon}
                    multipleCouponsCondition={multipleCouponsCondition}
                    data={data}
                  />
                )}
                {courseDetails.courseDuration && (
                  <CourseDuration
                    courseDuration={courseDetails.courseDuration}
                  />
                )}
                <WhatsIncluded
                  WhatsIncludedData={
                    courseDetails && courseDetails.otherFeatures
                  }
                  courseDuration={courseDetails.courseDuration}
                />
                <Coupons
                  setCouponAppliedSuccess={setCouponAppliedSuccess}
                  isCloseFormModal={this.props.isCloseFormModal}
                  closeFormModal={this.props.closeFormModal}
                  studentCouponList={this.props.studentCouponList}
                  triggerFormModal={this.props.triggerFormModal}
                  stateArray={stateArray}
                  completePageData={courseDetails}
                  priceDetails={priceDetails}
                  getUpdatedCouponsAndPriceDetails={
                    this.props.getUpdatedCouponsAndPriceDetails
                  }
                  handleViewAllFromForm={this.props.handleViewAllFromForm}
                  isHideCouponDetails={this.props.isHideCouponDetails}
                  toggleBothModals={this.props.toggleBothModals}
                  toggleFormModal={this.props.toggleFormModal}
                  toggleCouponModalFromHome={
                    this.props.toggleCouponModalFromHome
                  }
                  handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
                  openFormModal={this.props.openFormModal}
                  haveInvisibleCoupon={haveInvisibleCoupon}
                  multipleCouponsCondition={multipleCouponsCondition}
                />
                <div
                  style={{
                    display: "block",
                    width: "93.5%",
                    borderBottom: "1px solid rgba(229, 229, 229, 0.6)",
                    margin: "0 auto",
                  }}
                ></div>
                <AboutCourseCreator
                  courseCreatorDetails={courseDetails.courseCreatorDetails}
                  reference={this.props.reference}
                />
                {/* {window.screen.width < 700 ? <StudentViewedMobileView /> : <StudentViewedDesktopView />} */}
                {similarCourseArr && similarCourseArr.length > 0 ? (
                  <StudentViewedDesktopView
                    similarCourseArr={similarCourseArr}
                  />
                ) : (
                  ""
                )}
                {similarCourseArr && similarCourseArr.length > 0 ? (
                  <StudentViewedMobileView
                    similarCourseArr={similarCourseArr}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                {freeContent && freeContent.length > 0 ? (
                  <PreviewCourse
                    handleViewAllPreviewContent={
                      this.handleViewAllPreviewContent
                    }
                    freeContent={freeContent}
                    selectedTab={selectedTab}
                  />
                ) : (
                  ""
                )}
                <ContentList
                  isCloseFormModal={this.props.isCloseFormModal}
                  closeFormModal={this.props.closeFormModal}
                  selectedTab={selectedTab}
                  isInsideContent={1}
                  priceDetails={priceDetails}
                  stateArray={stateArray}
                  selectedTab={selectedTab}
                  freeContent={freeContent}
                  navFolderPushed={this.handleNavFolderPushed}
                  toggleBothModals={this.props.toggleBothModals}
                  toggleCouponModalFromHome={
                    this.props.toggleCouponModalFromHome
                  }
                  removeCouponApplied={this.props.removeCouponApplied}
                  getUpdatedCouponsAndPriceDetails={
                    this.props.getUpdatedCouponsAndPriceDetails
                  }
                  handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
                  openFormModal={this.props.openFormModal}
                  currentCouponCode={this.props.currentCouponCode}
                  haveInvisibleCoupon={haveInvisibleCoupon}
                  multipleCouponsCondition={multipleCouponsCondition}
                />
                <Coupons
                  isCloseFormModal={this.props.isCloseFormModal}
                  closeFormModal={this.props.closeFormModal}
                  studentCouponList={this.props.studentCouponList}
                  triggerFormModal={this.props.triggerFormModal}
                  stateArray={stateArray}
                  completePageData={courseDetails}
                  priceDetails={priceDetails}
                  getUpdatedCouponsAndPriceDetails={
                    this.props.getUpdatedCouponsAndPriceDetails
                  }
                  handleViewAllFromForm={this.props.handleViewAllFromForm}
                  isHideCouponDetails={this.props.isHideCouponDetails}
                  toggleBothModals={this.props.toggleBothModals}
                  toggleFormModal={this.props.toggleFormModal}
                  toggleCouponModalFromHome={
                    this.props.toggleCouponModalFromHome
                  }
                  handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
                  openFormModal={this.props.openFormModal}
                  notToShowCoupons={true}
                  haveInvisibleCoupon={haveInvisibleCoupon}
                  multipleCouponsCondition={multipleCouponsCondition}
                  setCouponAppliedSuccess={setCouponAppliedSuccess}
                />
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Tabview;
