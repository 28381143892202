import React from "react";
import axios from "axios";
import logo from "../src/images/logo.svg";
import MainPanel from "./MainPanel/MainPanel";
// import { withRouter } from "react-router-dom";
// import Loader from "./components/Loader/Loader";
import logoMobile from "./images/headerLogoMobile.svg";
import AppConfig from "./AppConfig";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { logEvent } from "./clevertap";
import CourseOverview from "../src/components/CourseOverview/CourseOverview";
import MoreCourses from "../src/MoreCourses/MoreCourses";
import shield from "../src/images/courseOverview/shield.svg";
import Form from "./Form/Form";
import { getQuery, makeid } from "./utils";
import PreviewCourse from "./components/PreviewCourse/PreviewCourse";
import shareButton from "./images/topviewDesktop/shareButton.svg";
import ShareModal from "./ShareModal/ShareModal";
import ContentList from "./components/ContentListCard/ContentList";
import back from "./images/backBtn.svg";
import { Link } from "react-router-dom";
import CourseListingPage from "./components/CourseListingPage/CourseListingPage";
import {
  getCourseCouponStudentOverview,
  getCourseCouponListStudent,
} from "./graphApis";
import { executeQuery } from "./graphApis/graph";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      courseDetail: null,
      completePageData: null,
      isFormDetails: false,
      isTabClicked: false,
      isShareBtn: false,
      courseLoading: true,
      seeMoreCourseUrl: "",
      courseTotalCount: 0,
      similarCourseArr: [],
      freeContent: [],
      highlights: [],
      stateArray: [],
      priceDetails: [],
      coursePriceValue: "",
      navFolderArr: [],
      isOverviewBtn: false,
      positionY: 0,
      isCloseFormModal: true,
      isHideCouponDetails: true,
      showCouponsOnForm: false,
      couponAppliedSuccess: false,
      haveInvisibleCoupon: true,
      multipleCouponsCondition: 2,
      // token: '/eyJjb3Vyc2VJZCI6MSwidHV0b3JJZCI6NzQ3Mn0='
    };
  }

  componentDidMount() {
    this._api();
    logEvent("SinglePayment_Link_Click");
    // window.addEventListener("scroll", this.handleScroll, true);
  }
  handleScroll = () => {
    this.setState({ positionY: window.pageYOffset });
  };

  handleFormDetails = () => {
    this.setState({ isFormDetails: false });
  };

  handleShareBtn = () => {
    this.setState({
      isShareBtn: false,
      shareUrl: this.state.completePageData.details.shareUrl,
    });
  };

  handleOverviewBtnClicked = (click) => {
    this.setState({ isOverviewBtn: click });
  };

  handleTabClicked = (isClicked) => {
    this.setState({
      isTabClicked: isClicked,
    });
  };
  triggerFormModal = (isTriggerModal) => {
    this.setState({
      isFormDetails: isTriggerModal,
      isCloseFormModal: !isTriggerModal,
    });
  };

  seeMoreCoursesApi = (limit, offset) => {
    const { seeMoreCourseUrl } = this.state;
    let courseListUrl = `${seeMoreCourseUrl}/?limit=${
      limit ? limit : 20
    }&offset=${offset ? offset : 0}`;
    const promoHeaders = {
      "Api-Version": 11,
      // 'x-access-token': getQuery()['hash'] ? getQuery()['hash'] : this.state.token
    };

    this.setState({ courseLoading: true }, () => {
      axios
        .get(`${courseListUrl}`, { headers: promoHeaders })
        .then((response) => {
          if (!this.state.courseTotalCount) {
            this.setState({
              courseLoading: false,
              similarCourseArr: response.data.data.coursesData,
              courseTotalCount: response.data.data.totalCount,
            });
          } else {
            this.setState({
              courseLoading: false,
              similarCourseArr: response.data.data.coursesData,
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          this.setState({ loading: false, error: "Internal server error" });
        });
    });
  };

  _api() {
    const promoHeaders = {
      "Api-Version": 11,
      // 'x-access-token': getQuery()['hash'] ? getQuery()['hash'] : this.state.token
    };
    let code;
    let code1 = window.location.href.match(new RegExp("[^/]+(?=/$|$)"))[0];

    if (code1.includes("token")) {
      code = getQuery()["token"];
    } else {
      let token = sessionStorage.getItem("token");
      if (token) {
        code = token;
      } else {
        code = code1;
      }
    }

    if (code) {
      sessionStorage.setItem("token", decodeURIComponent(code));
    }

    let tokenCode = decodeURIComponent(code);
    axios
      .get(`${AppConfig.BASE_URL}/v2/course/preview/${tokenCode}`, {
        headers: promoHeaders,
      })
      .then((response) => {
        if (response.data.data) {
          let cardData = response.data.data.cardData;
          let courseListUrl = cardData && cardData[0].url;
          courseListUrl = courseListUrl.split("limit")[0];
          this.setState(
            {
              completePageData: response.data.data,
              seeMoreCourseUrl: courseListUrl,
              freeContent: response.data.data.freeContent,
              highlights: response.data.data.highlights,
              stateArray: response.data.data.states,
              // priceDetails: response.data.data.priceDetails,
            },
            () => {
              this.seeMoreCoursesApi();
              this.getUpdatedCouponsAndPriceDetails(tokenCode);
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ loading: false, error: "Internal server error" });
      });
  }

  getUpdatedCouponsAndPriceDetails = (token, couponCode, source) => {
    // this.getCouponsList(token);
    return new Promise((resolve, reject) => {
      this.getCoursePriceDetails(token, couponCode, source)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getCouponsList = (token) => {
    let limit = 20;
    let offset = 0;
    executeQuery(getCourseCouponListStudent, {
      token,
      limit,
      offset,
    })
      .then((data) => {
        let resCoupons = data.data.data.coupons;
        let resText = data.data.data.label.text;
        let inVisibleCouponCount = data.data.data.inVisibleCouponCount;
        console.log(inVisibleCouponCount, "#####inVisibleCouponCount");
        this.setState(
          {
            studentCouponList:
              offset == 0
                ? resCoupons
                : [...this.state.studentCouponList, ...resCoupons],
            offset: offset + limit,
            couponHasMore: resCoupons.length > 19 ? true : false,
            dataFetching: false,
            loadingCoupons: false,
          },
          () => {
            // if (this.state.studentCouponList.coupons.length > 0) {

            if (this.state.studentCouponList.length) {
              sessionStorage.setItem("showCouponsOnForm", true);
              this.setState({
                showCouponsOnForm: true,
                haveInvisibleCoupon: inVisibleCouponCount >= 1 ? true : false,
                multipleCouponsCondition: inVisibleCouponCount >= 1 ? 1 : 2,
              });
              sessionStorage.setItem(
                "couponsModalHeading",
                "Coupons Available"
              );
              sessionStorage.setItem("couponsAvailableCount", resText);
            } else if (inVisibleCouponCount >= 1) {
              sessionStorage.setItem("showCouponsOnForm", true);
              this.setState({
                haveInvisibleCoupon: true,
                showCouponsOnForm: true,
                multipleCouponsCondition: 3,
              });
              sessionStorage.setItem(
                "couponsAvailableCount",
                "Apply coupon code"
              );
              sessionStorage.setItem(
                "couponsModalHeading",
                "Apply coupon code"
              );
            }
            // }
          }
        );
      })
      .catch((err) => {
        this.setState({
          loadingCoupons: false,
        });
        console.error(err, "****GraphError");
        console.error(err.message);
      });
  };

  getCoursePriceDetails = (token, code, source) => {
    let uniqueId = makeid(10);
    let payload = {
      token,
      uniqueId,
    };
    if (code && (code != "undefined" || code != "")) {
      payload = { ...payload, code: code };
    }

    if (source == "couponRemove") {
      let newPayload = {
        token,
        uniqueId,
        isRemove: true,
      };

      payload = newPayload;
    }

    return new Promise((resolve, reject) => {
      executeQuery(getCourseCouponStudentOverview, payload)
        .then(({ data }) => {
          this.setState(
            {
              priceDetails: data.data.coupons[0].priceDetails,
              coursePriceValue: data.data.label.button.val,
            },
            () => {
              sessionStorage.setItem(
                "coursePriceValue",
                data.data.label.button.val
              );

              sessionStorage.setItem("uniqueId", uniqueId);
              if (code && code != "undefined") {
                toast.success("Coupon Applied Successfully");
              }
            }
          );

          this.getCouponsList(token);
          resolve(data);
        })
        .catch((err) => {
          this.setState({
            loadingCoupons: false,
          });
          console.error(err, "****GraphError");
          toast.error(err.message);
          reject(err);
        });
    });
  };

  handleViewAllFromForm = (cb, args) => {
    cb();
  };

  toggleCouponModalFromHome = (couponModalState) => {
    this.setState(
      {
        isHideCouponDetails:
          couponModalState != undefined
            ? couponModalState
            : !this.state.isHideCouponDetails,
      },
      () => {
        console.log(this.state.isHideCouponDetails, "++++isHideCouponDetails");
      }
    );
  };

  toggleFormModal = () => {
    this.setState({
      isFormDetails: !this.state.isFormDetails,
      isCloseFormModal: this.state.isFormDetails,
    });
  };

  toggleBothModals = () => {
    this.closeFormModal();
    this.toggleCouponModalFromHome();
  };

  removeCouponApplied = () => {
    let token = sessionStorage.getItem("token");
    sessionStorage.setItem("currentCouponCode", JSON.stringify({}));
    this.getUpdatedCouponsAndPriceDetails(token, "", "couponRemove");
  };

  handleGetApp = () => {
    const { completePageData } = this.state;
    window.open(completePageData && completePageData.details.appLink);
  };

  handleNavFolderPushed = (data) => {
    this.setState({
      navFolderArr: data,
    });
  };

  closeFormModal = (value) => {
    this.setState({
      isCloseFormModal: !this.state.isCloseFormModal,
      isFormDetails: value,
    });
  };

  openFormModal = () => {
    this.setState({
      isCloseFormModal: false,
      isFormDetails: true,
    });
  };

  setCouponAppliedSuccess = () => {
    this.setState(
      {
        couponAppliedSuccess: !this.state.couponAppliedSuccess,
      },
      () => {
        sessionStorage.setItem(
          "couponAppliedSuccess",
          this.state.couponAppliedSuccess
        );
        setTimeout(() => {
          this.setState({ couponAppliedSuccess: false });
        }, 3000);
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.couponAppliedSuccess != this.state.couponAppliedSuccess) {
      if (this.state.couponAppliedSuccess == true) {
        console.log("*******popup");
        // setTimeout(this.setState({ couponAppliedSuccess: false }), 3000);
      }
    }
  }

  render() {
    let {
      isTabClicked,
      courseLoading,
      courseTotalCount,
      isShareBtn,
      completePageData,
      shareUrl,
      similarCourseArr,
      freeContent,
      highlights,
      stateArray,
      priceDetails,
      isOverviewBtn,
      positionY,
      navFolderArr,
      isCloseFormModal,
      isFormDetails,
      showCouponsOnForm,
      studentCouponList,
      coursePriceValue,
      isHideCouponDetails,
      couponAppliedSuccess,
      haveInvisibleCoupon,
      multipleCouponsCondition,
    } = this.state;

    let token = sessionStorage.getItem("token");
    let couponCode = JSON.parse(sessionStorage.getItem("currentCouponCode"));
    return (
      <div className="App">
        {window.location.href.match("/Preview") ||
        window.location.href.match("/MoreCourses") ? null : ( // </div> //     Course Contents //     </Link> //     </button> //       <img src={back} alt="" style={{ marginRight: "24px" }} /> //     > //       // }} //       //   this.props.history.push("/"); //       // onClick={() => { //       className="backBtn" //     <button //     <Link to="/"> //   <div className="header">
          <div
            className="AppHeader"
            style={
              window.screen.width > 1000
                ? { position: "fixed" }
                : { position: "static" }
            }
          >
            {positionY > 130 && window.screen.width > 1000 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "65%",
                  alignItems: "center",
                }}
              >
                <p className="course-name">{completePageData.details.name}</p>
                <button
                  className="shareButton"
                  onClick={() => {
                    this.setState({ isShareBtn: true });
                  }}
                >
                  <img
                    src={shareButton}
                    alt="share"
                    style={{ marginRight: "10px" }}
                  />
                  Share
                </button>
              </div>
            ) : (
              <>
                <div className="AppHeader-left">
                  {window.screen.width > 1000 ? (
                    <img src={logo} alt="" />
                  ) : (
                    <img src={logoMobile} alt="" />
                  )}
                  <p className="AppHeaderText">
                    {completePageData
                      ? completePageData.courseCreatorDetails.orgName
                      : ""}
                  </p>
                </div>
                <button onClick={this.handleGetApp} className="AppHeader-right">
                  Get the app
                </button>
              </>
            )}

            {/* {positionY >250 && window.screen.width > 1000? (
            <p style={{ marginLeft: "150px" }}>{completePageData.details.name}</p>
          ) : (
            <div className="AppHeader-left">
              <img src={logo} alt="" />
              <p className="AppHeaderText">
                {completePageData
                  ? completePageData.courseCreatorDetails.orgName
                  : ""}
              </p>
            </div>
           )} 

          {positionY > 250  && window.screen.width > 1000 ? (
            <button
              className="shareButton"
              onClick={() => {
                this.setState({ isShareBtn: true });
              }}
            >
              <img
                src={shareButton}
                alt="share"
                style={{ marginRight: "10px" }}
              />
              Share
            </button>
          ) : ( 
            <button onClick={this.handleGetApp} className="AppHeader-right">Get the app</button>
         )}  */}
          </div>
        )}
        <ShareModal
          shareUrl={shareUrl}
          isShareBtn={isShareBtn}
          handleShareBtn={this.handleShareBtn}
        />

        <div className="AppContainer">
          <ToastContainer autoClose={3000} />

          <Router>
            <Switch>
              <Route
                path="/Preview"
                exact
                render={() => (
                  <div>
                    <CourseListingPage
                      isCloseFormModal={isCloseFormModal}
                      closeFormModal={this.closeFormModal}
                      navFolderArr={navFolderArr}
                      freeContent={freeContent}
                      isInsideContent={1}
                      priceDetails={priceDetails}
                      freeContent={freeContent}
                      stateArray={stateArray}
                      completePageData={completePageData}
                      handleNavFolderPushed={this.handleNavFolderPushed}
                      toggleBothModals={this.toggleBothModals}
                      toggleCouponModalFromHome={this.toggleCouponModalFromHome}
                      removeCouponApplied={this.removeCouponApplied}
                      setCouponAppliedSuccess={this.setCouponAppliedSuccess}
                      couponAppliedSuccess={couponAppliedSuccess}
                      getUpdatedCouponsAndPriceDetails={
                        this.getUpdatedCouponsAndPriceDetails
                      }
                      handleOverviewBtnClicked={this.handleOverviewBtnClicked}
                      openFormModal={this.openFormModal}
                      priceDetails={priceDetails}
                      stateArray={stateArray}
                      isOverviewBtn={isOverviewBtn}
                      studentCouponList={studentCouponList}
                      triggerFormModal={this.triggerFormModal}
                      getUpdatedCouponsAndPriceDetails={
                        this.getUpdatedCouponsAndPriceDetails
                      }
                      handleViewAllFromForm={this.handleViewAllFromForm}
                      isHideCouponDetails={isHideCouponDetails}
                      toggleBothModals={this.toggleBothModals}
                      toggleFormModal={this.toggleFormModal}
                      toggleCouponModalFromHome={this.toggleCouponModalFromHome}
                      handleOverviewBtnClicked={this.handleOverviewBtnClicked}
                      removeCouponApplied={this.removeCouponApplied}
                      openFormModal={this.openFormModal}
                      currentCouponCode={couponCode}
                      haveInvisibleCoupon={haveInvisibleCoupon}
                      multipleCouponsCondition={multipleCouponsCondition}
                    />

                    {/* {freeContent && freeContent.length > 0 ? navFolderArr.length > 0 ? '' : <PreviewCourse freeContent={freeContent} /> : '' }
                  <ContentList isInsideContent={1} priceDetails={priceDetails}
                    freeContent={freeContent}
						  stateArray={stateArray}
						  data={completePageData}
              navFolderPushed = {this.handleNavFolderPushed}
				  /> */}
                  </div>
                )}
              />
              <Route
                path="/MoreCourses"
                exact
                render={() => (
                  <MoreCourses
                    courseTotalCount={courseTotalCount}
                    courseLoading={courseLoading}
                    similarCourseArr={similarCourseArr}
                    fetchMoreCourses={this.seeMoreCoursesApi}
                    orgName={
                      completePageData &&
                      completePageData.auxiliaryData &&
                      completePageData.auxiliaryData.organisationName
                    }
                  />
                )}
              />
              <Route
                path="/"
                render={() =>
                  completePageData ? (
                    <div className="AppContainer-div">
                      {/* <div className="AppContainer-div-upper"></div> */}{" "}
                      <MainPanel
                        isCloseFormModal={isCloseFormModal}
                        isFormDetails={isFormDetails}
                        closeFormModal={this.closeFormModal}
                        data={completePageData}
                        isTabClicked={isTabClicked}
                        handleTabClicked={this.handleTabClicked}
                        freeContent={freeContent}
                        similarCourseArr={similarCourseArr}
                        priceDetails={priceDetails}
                        stateArray={stateArray}
                        isOverviewBtn={isOverviewBtn}
                        studentCouponList={studentCouponList}
                        triggerFormModal={this.triggerFormModal}
                        getUpdatedCouponsAndPriceDetails={
                          this.getUpdatedCouponsAndPriceDetails
                        }
                        handleViewAllFromForm={this.handleViewAllFromForm}
                        isHideCouponDetails={isHideCouponDetails}
                        toggleBothModals={this.toggleBothModals}
                        toggleFormModal={this.toggleFormModal}
                        toggleCouponModalFromHome={
                          this.toggleCouponModalFromHome
                        }
                        handleOverviewBtnClicked={this.handleOverviewBtnClicked}
                        removeCouponApplied={this.removeCouponApplied}
                        openFormModal={this.openFormModal}
                        setCouponAppliedSuccess={this.setCouponAppliedSuccess}
                        couponAppliedSuccess={couponAppliedSuccess}
                        currentCouponCode={couponCode}
                        haveInvisibleCoupon={haveInvisibleCoupon}
                        multipleCouponsCondition={multipleCouponsCondition}
                      />
                      <div className="AppContainer-div-right">
                        <CourseOverview
                          handleOverviewBtnClicked={
                            this.handleOverviewBtnClicked
                          }
                          isCloseFormModal={isCloseFormModal}
                          closeFormModal={this.closeFormModal}
                          stateArray={stateArray}
                          data={completePageData}
                          courseHighlights={highlights}
                          priceDetails={priceDetails}
                          toggleBothModals={this.toggleBothModals}
                          removeCouponApplied={this.removeCouponApplied}
                          getUpdatedCouponsAndPriceDetails={
                            this.getUpdatedCouponsAndPriceDetails
                          }
                          toggleCouponModalFromHome={
                            this.toggleCouponModalFromHome
                          }
                          showCouponsOnForm={this.state.showCouponsOnForm}
                          openFormModal={this.openFormModal}
                          setCouponAppliedSuccess={this.setCouponAppliedSuccess}
                          couponAppliedSuccess={couponAppliedSuccess}
                          currentCouponCode={couponCode}
                          haveInvisibleCoupon={haveInvisibleCoupon}
                          multipleCouponsCondition={
                            this.state.multipleCouponsCondition
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              />
            </Switch>
          </Router>
          <div className="mobileFooter">
            {positionY < 20 && (
              <div className="shieldDiv">
                <img src={shield} alt="" />
                <p className="footerText">Secure payment with Razorpay</p>
              </div>
            )}
            <button
              className="mobileBtn"
              onClick={() => {
                this.triggerFormModal(true);
                this.getUpdatedCouponsAndPriceDetails(
                  token,
                  couponCode ? couponCode.code : null
                );
              }}
            >
              Buy now for ₹ {coursePriceValue / 100}/-
            </button>
            {completePageData ? (
              <Form
                isHideCouponDetails={isHideCouponDetails}
                isCloseFormModal={isCloseFormModal}
                closeFormModal={this.closeFormModal}
                stateArray={stateArray}
                isFormDetails={isFormDetails}
                handleFormDetails={this.handleFormDetails}
                data={completePageData}
                priceDetails={priceDetails}
                handleOverviewBtnClicked={this.handleOverviewBtnClicked}
                formOpenViaCoupons={false}
                studentCouponList={studentCouponList}
                handleViewAllFromForm={this.handleViewAllFromForm}
                toggleBothModals={this.toggleBothModals}
                toggleCouponModalFromHome={this.toggleCouponModalFromHome}
                removeCouponApplied={this.removeCouponApplied}
                currentCouponCode={couponCode}
                couponAppliedSuccess={couponAppliedSuccess}
                setCouponAppliedSuccess={this.setCouponAppliedSuccess}
                getUpdatedCouponsAndPriceDetails={
                  this.getUpdatedCouponsAndPriceDetails
                }
                haveInvisibleCoupon={haveInvisibleCoupon}
                multipleCouponsCondition={multipleCouponsCondition}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
