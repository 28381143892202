import React, { Component } from "react";
import Modal from "../../UI/Modal/Modal";
import { Grid, Loader } from "semantic-ui-react";
import FormatModal from "../../UI/FormatModal/FormatModal";
import Tag from "../../images/CouponTag.svg";
import CrossModal from "../../images/crossModal.svg";
import SVGClock from "../../images/ContentList/clock.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeid } from "../../utils";
import {
  getCourseCouponStudentOverview,
  getCourseCouponListStudent,
} from "../../graphApis";
import { executeQuery } from "../../graphApis/graph";
import { ToastContainer, toast } from "react-toastify";

export class CouponsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputCouponCode: "",
    };
  }

  changeCouponCodeValue = (e) => {
    this.setState({
      inputCouponCode: e.target.value.toUpperCase(),
    });
  };

  render() {
    let {
      toggleCouponModal,
      studentCouponList,
      toggleCouponFromHome,
      hasMore,
      totalCount,
      getCouponsList,
      loadingCoupons,
      isCloseFormModal,
      haveInvisibleCoupon,
      multipleCouponsCondition,
    } = this.props;
    console.log(multipleCouponsCondition, "+++++multipleCouponsConditionModal");
    let couponsAvailableCount = sessionStorage.getItem("couponsAvailableCount");
    let token = sessionStorage.getItem("token");
    let couponsModalHeading = sessionStorage.getItem("couponsModalHeading");

    return (
      <div style={{ zIndex: "1000" }}>
        {
          <Modal
            hidden={this.props.isHideCouponDetails}
            hideClose={true}
            style={{ zIndex: "1000" }}
          >
            <FormatModal>
              <div className="CouponsModalTop">
                <div className="CouponModalLabel">
                  <img src={Tag} />
                  <div>{couponsAvailableCount}</div>
                </div>

                <button
                  onClick={() => {
                    toggleCouponModal(0);

                    this.props.handleOverviewBtnClicked(!isCloseFormModal);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={CrossModal} style={{ cursor: "pointer" }} />
                </button>
              </div>
              {multipleCouponsCondition !== 2 && (
                <div
                  className="enterCouponWrapper"
                  style={{
                    marginBottom:
                      multipleCouponsCondition == 3 ? "20px" : "unset",
                  }}
                >
                  <div className="enterCoupon">
                    <input
                      className="enterCouponField"
                      type="text"
                      value={this.state.inputCouponCode}
                      onChange={(e) => {
                        this.changeCouponCodeValue(e);
                      }}
                      placeholder="Enter a coupon code"
                    />
                    <button
                      disabled={!this.state.inputCouponCode.length > 3}
                      style={{
                        color:
                          this.state.inputCouponCode.length > 3
                            ? "#009AE0"
                            : "#999999",
                      }}
                      className="enterCouponButton"
                      onClick={() => {
                        this.props.handleCouponApplied({
                          code: this.state.inputCouponCode,
                        });
                      }}
                    >
                      Buy using this
                    </button>
                  </div>
                </div>
              )}

              {studentCouponList &&
              studentCouponList.length &&
              studentCouponList.length > 0 ? (
                <div className="couponList">
                  <InfiniteScroll
                    next={() => getCouponsList()}
                    hasMore={hasMore}
                    dataLength={studentCouponList && studentCouponList.length}
                    loader={
                      loadingCoupons && (
                        <Grid centered>
                          <Grid.Column stretched width={10}>
                            <Loader active inline="centered" />
                          </Grid.Column>
                        </Grid>
                      )
                    }
                    height={
                      studentCouponList &&
                      studentCouponList.length != totalCount
                        ? "60vh"
                        : "auto"
                    }
                    style={{ overflowX: "hidden" }}
                  >
                    {studentCouponList &&
                      studentCouponList.map((couponList, i) => {
                        return (
                          <div key={i} className="CouponListItem">
                            {couponList &&
                            couponList.coupon &&
                            couponList.coupon.label ? (
                              <div className="CouponModalLabel">
                                {couponList &&
                                  couponList.coupon &&
                                  couponList.coupon.label}
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="CouponModalRow1">
                              {couponList &&
                              couponList.coupon &&
                              couponList.coupon.name ? (
                                <div className="CouponModalName">
                                  {couponList &&
                                    couponList.coupon &&
                                    couponList.coupon.name}
                                </div>
                              ) : (
                                ""
                              )}
                              {couponList &&
                              couponList.coupon &&
                              couponList.coupon.expire ? (
                                <div className="CouponModalValid">
                                  <img
                                    src={SVGClock}
                                    style={{ marginRight: "8px" }}
                                  />

                                  {couponList.coupon.expire.text
                                    ? couponList.coupon.expire.text
                                    : couponList.coupon.expire}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="CouponModalRow2">
                              {couponList &&
                              couponList.coupon &&
                              couponList.coupon.code ? (
                                <div className="CouponModalCode">
                                  {couponList &&
                                    couponList.coupon &&
                                    couponList.coupon.code}
                                </div>
                              ) : (
                                ""
                              )}
                              <div
                                className="CouponModalLink"
                                onClick={() =>
                                  this.props.handleCouponApplied(
                                    couponList && couponList.coupon
                                  )
                                }
                              >
                                Buy Using This
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </InfiniteScroll>
                </div>
              ) : (
                ""
              )}
            </FormatModal>
          </Modal>
        }
      </div>
    );
  }
}

export default CouponsModal;
