import React from "react";

function FreeContent({ item }) {
  return (
    item &&
    item.tag &&
    item.tag.text && (
      <div
        style={{
          // position: "absolute",
          backgroundColor: `${item && item.tag && item.tag.bgColor ? item.tag.bgColor : ""
            }`,
          color: `${item && item.tag && item.tag.color ? item.tag.color : ""}`,
          fontSize: "12px",
          left: "0px",
          width: "max-content",
          padding: "0 0.5rem",
          // top: "45px",
          borderRadius: "4px",
          textAlign: "center",
          marginTop: "8px"
        }}
      >
        {item && item.tag && item.tag.text}
      </div>
    )
  );
}

export default FreeContent;
