import axios from "axios";
import AppConfig from "../AppConfig";

export function executeQuery(query, variables = {}) {
  // variables = { ...variables, token };
  return new Promise((resolve, reject) => {
    axios
      .post(`${AppConfig.API_GRAPH_URL}`, {
        query: query,
        variables: variables,
      })
      .then((response) => {
        let { errors } = response.data;
        if (errors)
          return reject({ message: errors[0].message, data: response.data });
        return resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
