import React, { Component } from "react";
import classes from "./Form.module.css";
import { FormErrors } from "../FormErrors/FormErrors.js";
import Input from "../Input/Input";
import { checkValidity } from "../utils";
import razorPayKey from "../RazorPay/RazorPay";
import ReactDOM from "react-dom";
import webLoginLogo from "../images/web-login-logo.png";
import verified from "../images/verified.svg";
import succesGif from "../images/succesGif.gif";
import succesGifDesktop from "../images/succesGifDesktop.gif";
import AppConfig from "../AppConfig";
import axios from "axios";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import countryExt from "../Form/countryEXtensions";
import _ from "underscore";
import Modal from "../UI/Modal/Modal";
import FormatModal from "../UI/FormatModal/FormatModal";
import { toast } from "react-toastify";
import { logEvent, setProfile as setCTProfile } from "../clevertap";

import CouponApplied from "../components/Coupons/CouponApplied";

import modalImage from "../images/modalImage.svg";
import backButton from "../images/backBtnDesktop.svg";
import securePaymentImg from "../images/securePaymentImg.svg";
import OtpInput from "react-otp-input";
import RadioBtnImg from "../images/RadioBtnImg.svg";
import nextArrow from "../images/nextArrow.svg";
import msgIcon from "../images/msgIcon.svg";
import crossImg from "../images/crossModal.svg";
import CouponSuccess from "../images/CouponSuccess.svg";
import NoCouponTag from "../images/NoCouponTag.svg";
import {
  validatingAppliedCoupon,
  removeCourseCouponStudent,
} from "../graphApis";
import { executeQuery } from "../graphApis/graph";
// import { withRouter } from 'react-router-dom';

class SidePanel extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      // isCloseModal: false,
      name: "",
      email: "",
      userId: "",
      number: "",
      selectedStateVal: "",
      emailDisabled: false,
      nameDisabled: false,
      stateDisabled: false,
      formErrors: { email: "", number: "" },
      nameValid: false,
      recieptUrl: "",
      emailValid: false,
      numberValid: false,
      isDownloadModal: false,
      errorBuyNow: false,
      existingUserError: false,
      formValid: false,
      isVerify: false,
      isCityClick: false,
      token: "",
      signedUp: "",
      otpValue: "",
      otpStyle: {
        border: "1px solid #E5E5E5",
        borderRadius: "4px",
        padding: "13px",
        fontSize: "16px",
        lineHeight: "20px",
        color: "#000",
        outline: "none",
        isDesktopClick: false,
        isDesktopBackClick: false,
      },
      otpNum: "",
      controls: [
        {
          type: "text",
          name: "Country Extension",
          placeholder: "Enter country extension number here",
          value: "91",

          validations: {
            required: true,
            pattern: /^\d{1,4}$/,
          },
          touched: false,
          CT_EVENT: "SinglePayment_Dropdown_Click",
        },
        {
          type: "number",
          name: "Mobile Number",
          placeholder: "Enter mobile number here",
          errorMessage: "Invalid Mobile Number",
          value: "",

          validations: {
            required: true,
            pattern: /^\d{5,15}$/,
          },
          touched: false,
          CT_EVENT: "SinglePayment_Mobile",
        },
        {
          type: "text",
          name: "Name*",
          placeholder: "e.g. Devansh Arora",
          value: "",
          errorMessage: "Name should have atleast 3 characters",
          validations: {
            required: true,
            pattern: /(.*[a-zA-Z0-9]){3}/i,
          },
          touched: false,
          CT_EVENT: "SinglePayment_Name",
        },
        {
          type: "email",
          name: "Email ID*",
          placeholder: "devansharora@email.com",
          value: "",
          errorMessage: "Please enter a valid email",
          validations: {
            required: true,
            pattern: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
          },
          touched: false,
          CT_EVENT: "SinglePayment_Email",
        },
        {
          type: "text",
          name: "State",
          placeholder: "Select a state",
          value: "",
          errorMessage: "State should be added",
          validations: {
            required: true,
            pattern: "",
          },
          touched: false,
          // CT_EVENT: 'SinglePayment_State'
        },
      ],
      cityNameData: [
        { city: "New Delhi" },
        { city: "Andaman & Nicobar Islands" },
        { city: "Andhra Pradesh" },
        { city: "Arunachal Pradesh" },
        { city: "Assam" },
        { city: "Bihar" },
        { city: "Chandigarh" },
        { city: "Chattisgarh" },
        { city: "Karnataka" },
        { city: "Maharashtra" },
      ],
      stateNameData: [
        { value: "Maharashtra", label: "Maharashtra" },
        { value: "Uttar Pradesh", label: "Uttar Pradesh" },
      ],
      otpTimer: 30,
      searchResult: [],
      searchTerm: "",
      redeemId: "",
      isLoggedIn: false,
    };
    this.timer = 0;
  }

  startTimer = () => {
    if (this.state.otpTimer > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    let otpTimer = this.state.otpTimer - 1;
    this.setState({
      otpTimer: otpTimer,
    });
    if (otpTimer == 0) {
      clearInterval(this.timer);
    }
  };

  initialStateValues = ["91", "", "", ""];

  handleModal = () => {
    this.setState({
      isVerify: false,
      otpValue: "",
    });
    // this.setInputFocus()
  };

  setInputFocus = () => {
    // ReactDOM.findDOMNode(this.myInputRef).focus();
    this.textInput.current.focus();
  };

  handleCityModal = () => {
    this.setState({
      isCityClick: false,
    });
  };

  handleCity = () => {
    this.setState({
      isCityClick: true,
    });
  };

  handleDownloadModal = () => {
    let controls = [...this.state.controls];
    controls = controls.map((control, index) => {
      control.value = this.initialStateValues[index];
      control.touched = false;
      return control;
    });
    this.setState({
      controls,
      isDownloadModal: false,
      otpValue: "",
      formValid: false,
    });
    window.scrollTo(0, 0);
  };

  // handleBuyButton=()=>{
  // 	  this.setState({isVerify:true});

  // }

  handleVerifyOtp = () => {
    // const { currentCouponCode } = this.props;
    let currentCouponCode = JSON.parse(
      sessionStorage.getItem("currentCouponCode")
    );
    let coursePriceValue = sessionStorage.getItem("coursePriceValue");
    this.setState({
      isVerify: false,
    });
    let params = {
      countryExt: this.state.controls[0].value,
      orgId: this.props.data.auxiliaryData.orgId,
      viaSms: "1",
      mobile: this.state.controls[1].value,
      state: this.state.controls[4].value,
      // email: this.state.controls[3].value
    };

    if (this.state.controls[0].value !== "91") {
      params = {
        countryExt: this.state.controls[0].value,
        orgId: this.props.data.auxiliaryData.orgId,
        viaEmail: "1",
        email: this.state.controls[3].value,
        state: this.state.controls[4].value,
      };
    }
    params.sessionId = this.state.sessionId;
    if (this.state.otpValue && /^\d{4}$/.test(this.state.otpValue)) {
      params.otp = this.state.otpValue;
    } else {
      toast.error("Please enter valid input as otp");
      return;
    }
    axios
      .post(`${AppConfig.BASE_URL}/v2/course/preview/users/verify`, params)
      .then((response) => {
        this.setState(
          {
            exists: response.data.data.user.exists,
            signedUp: response.data.data.user.signedUp,
            userId: response.data.data.user.id,
          },
          () => {
            if (this.state.exists == 0 || this.state.signedUp == 0) {
              let reqBody = {
                contact: {
                  mobile: this.state.controls[1].value,
                  email: this.state.controls[3].value,
                  countryExt: this.state.controls[0].value,
                  state: this.state.controls[4].value,
                },
                type: "1",
                orgId: this.props.data.auxiliaryData.orgId,
                otp: this.state.otpValue,
                sessionId: this.state.sessionId,
                name: this.state.controls[2].value,
              };
              axios
                .post(
                  `${AppConfig.BASE_URL}/v2/course/preview/users/register`,
                  reqBody
                )
                .then((response) => {
                  this.setState(
                    {
                      token: response.data.data.token,
                      isLoggedIn: true,
                    },
                    () => {
                      if (response.status >= 200 && response.status < 300) {
                        let recordData = {
                          contact: {
                            mobile: this.state.controls[1].value,
                            email: this.state.controls[3].value,
                            countryExt: this.state.controls[0].value,
                            state: this.state.controls[4].value,
                          },
                          orgName: this.props.data.auxiliaryData
                            .organisationName,
                          name: this.state.controls[2].value,
                          orgId: this.props.data.auxiliaryData.orgId,
                          courseId: this.props.data.auxiliaryData.id,
                          amount: this.props.data.auxiliaryData.price,
                          discount: this.props.data.auxiliaryData.discount,
                          finalAmount: coursePriceValue,
                        };

                        let isCouponApplied = 0;
                        let code = currentCouponCode && currentCouponCode.code;
                        if (code) {
                          isCouponApplied = 1;
                        }
                        if (isCouponApplied) {
                          let uniqueId = sessionStorage.getItem("uniqueId");
                          let token = this.state.token;
                          let payload = {
                            token,
                            uniqueId,
                          };

                          executeQuery(validatingAppliedCoupon, payload)
                            .then(({ data }) => {
                              if (
                                data &&
                                data.data &&
                                data.data.withAuth &&
                                data.data.withAuth.user &&
                                data.data.withAuth.user.student &&
                                data.data.withAuth.user.student
                                  .mapAnonymousCoupon &&
                                data.data.withAuth.user.student
                                  .mapAnonymousCoupon.id
                              ) {
                                let redeemId =
                                  data.data.withAuth.user.student
                                    .mapAnonymousCoupon.id;
                                this.setState(
                                  {
                                    redeemId,
                                  },
                                  () => {
                                    this.handleAddToCartApi();
                                  }
                                );
                              } else {
                                this.handleAddToCartApi();
                              }
                            })
                            .catch((err) => {
                              this.setState({
                                loadingCoupons: false,
                              });
                              console.error(err, "****GraphErrorObject");
                              console.error(err.status, "****GraphErrorStatus");
                              console.error(
                                err.response,
                                "****GraphErrorResponse"
                              );
                              if (err == "Network Error") {
                                toast.error(
                                  "Network Issue, Please reapply the coupon and try again"
                                );
                              } else toast.error(err.message);
                            });
                        } else {
                          this.handleAddToCartApi();
                        }
                      }
                    }
                  );
                })
                .catch((error) => {
                  toast.error(error.response.data.message);
                });
            } else {
              this.setState(
                {
                  token: response.data.data.token,
                  isLoggedIn: true,
                },
                () => {
                  if (response.status >= 200 && response.status < 300) {
                    let recordData = {
                      contact: {
                        mobile: this.state.controls[1].value,
                        email: this.state.controls[3].value,
                        countryExt: this.state.controls[0].value,
                        state: this.state.controls[4].value,
                      },
                      orgName: this.props.data.auxiliaryData.organisationName,
                      name: this.state.controls[2].value,
                      orgId: this.props.data.auxiliaryData.orgId,
                      courseId: this.props.data.auxiliaryData.id,
                      amount: this.props.data.auxiliaryData.price,
                      discount: this.props.data.auxiliaryData.discount,
                      finalAmount: coursePriceValue,
                    };

                    let isCouponApplied = 0;
                    let code = currentCouponCode && currentCouponCode.code;
                    if (code) {
                      isCouponApplied = 1;
                    }
                    if (isCouponApplied) {
                      let uniqueId = sessionStorage.getItem("uniqueId");
                      let token = this.state.token;
                      let payload = {
                        token,
                        uniqueId,
                      };

                      executeQuery(validatingAppliedCoupon, payload)
                        .then(({ data }) => {
                          if (
                            data &&
                            data.data &&
                            data.data.withAuth &&
                            data.data.withAuth.user &&
                            data.data.withAuth.user.student &&
                            data.data.withAuth.user.student
                              .mapAnonymousCoupon &&
                            data.data.withAuth.user.student.mapAnonymousCoupon
                              .id
                          ) {
                            let redeemId =
                              data.data.withAuth.user.student.mapAnonymousCoupon
                                .id;
                            this.setState(
                              {
                                redeemId,
                              },
                              () => {
                                this.handleAddToCartApi();
                              }
                            );
                          } else {
                            this.handleAddToCartApi();
                          }
                        })
                        .catch((err) => {
                          this.setState({
                            loadingCoupons: false,
                          });
                          console.error(err, "****GraphErrorObject");
                          console.error(err.status, "****GraphErrorStatus");
                          console.error(err.response, "****GraphErrorResponse");
                          if (err == "Network Error") {
                            toast.error(
                              "Network Issue, Please reapply the coupon and try again"
                            );
                          } else toast.error(err.message);
                        });
                    } else {
                      this.handleAddToCartApi();
                    }
                  }
                }
              );
            }
          }
        );
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (this.instance) {
          this.instance.close();
        }
        document.getElementsByClassName("razorpay-container")[0].style.display =
          "none";
      });
  };

  handleAddToCartApi = (response) => {
    let currentCouponCode = JSON.parse(
      sessionStorage.getItem("currentCouponCode")
    );
    let isCouponApplied = 0;
    let code = currentCouponCode && currentCouponCode.code;
    let coursePriceValue = sessionStorage.getItem("coursePriceValue");
    if (code) {
      isCouponApplied = 1;
    }
    let addToCartPayload = {
      courseList: [this.props.data.auxiliaryData.id],
      isCouponApplied: isCouponApplied,
      ifSinglePayment: 1,
      currentAmount: coursePriceValue,
      couponCode: code ? code : null,
    };
    const { redeemId } = this.state;
    // console.log(redeemId, "+++++redeemId");
    let updatedPayload = {
      ...addToCartPayload,
      redemptionId: redeemId ? redeemId : null,
    };

    if (redeemId == null && isCouponApplied) {
      updatedPayload = {
        courseList: [this.props.data.auxiliaryData.id],
        ifSinglePayment: 1,
        currentAmount: coursePriceValue,
        isCouponApplied: 0,
        code: null,
      };
      console.log("((((((NETWORK CONDITION", updatedPayload);
    }

    console.log(updatedPayload, "(((((((updatedPayload");
    axios
      .post(`${AppConfig.BASE_URL}/v2/course/cart/addNew`, updatedPayload, {
        headers: {
          "Api-Version": 11,
          "x-access-token": this.state.token,
        },
      })
      .then((response) => {
        this.setState(
          {
            generatedOrderId: response.data.data.orderId,
          },
          () => {
            this.handlePayment();
          }
        );
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        this.setState({
          errorBuyNow: true,
          // loading : false
        });
        if (this.instance) {
          this.instance.close();
        }
        document.getElementsByClassName("razorpay-container")[0].style.display =
          "none";
        // console.log(error);
        return;
      });
  };

  handleOtpInput = (e) => {
    if (e.target.value.length <= 4) {
      this.setState({
        otpValue: e.target.value,
      });
    }
  };

  removeCouponWhenLogin = (code, redemptionId) => {
    let queryToken = sessionStorage.getItem("token");
    executeQuery(removeCourseCouponStudent, {
      token: this.state.token,
      code: `${code.toUpperCase()}`,
      redemptionId: `${redemptionId}`,
    })
      .then((res) => {
        // console.log(res, "++++Res");
        toast.info("Please reapply your coupon to get discount!");
        sessionStorage.setItem("currentCouponCode", JSON.stringify({}));
        this.props.getUpdatedCouponsAndPriceDetails(queryToken, "");
      })
      .catch((err) => {
        console.error(err, "****GraphErrorObject");
        console.error(err.status, "****GraphErrorStatus");
        console.error(err.response, "****GraphErrorResponse");
        console.error(err, "++++err");
      });
  };

  handlePayment = () => {
    const _this = this;
    let coursePriceValue = sessionStorage.getItem("coursePriceValue");
    const { currentCouponCode } = this.props;
    const { redeemId } = this.state;
    let isCouponApplied = 0;
    let code = currentCouponCode && currentCouponCode.code;
    if (code) {
      isCouponApplied = 1;
    }
    const options = {
      key: razorPayKey,
      amount: parseInt(coursePriceValue),
      image: webLoginLogo,
      currency: "INR",
      notes: {
        // courseId: _this.props.data.auxiliaryData.id,
        orgId: _this.props.data.auxiliaryData.orgId,
        deviceType: /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
          ? "ANDROID"
          : /iPhone|iPad|iPod/i.test(navigator.userAgent)
          ? "IOS"
          : "WEB",
        type: "cart",
        orderId: _this.state.generatedOrderId,
        isCouponApplied: isCouponApplied,
        couponCode: code ? code : null,
        redemptionId: isCouponApplied ? redeemId : null,
        userId: _this.state.userId,
      },
      handler(response) {
        // console.log(response.razorpay_payment_id,this)
        // _this.setState({
        // 	transactionId : response.razorpay_payment_id
        // })
        let params = {
          paymentTransactionId: response.razorpay_payment_id,
          totalAmount: coursePriceValue,

          state: _this.state.controls[4].value, //courseId,
          orderId: _this.state.generatedOrderId,
          redemptionId: isCouponApplied ? redeemId : null,
          isCouponApplied: isCouponApplied,
          couponCode: code ? code : null,
        };
        axios
          .post(`${AppConfig.BASE_URL}/v2/course/cart/subscribe`, params, {
            headers: {
              "Api-Version": 11,
              "x-access-token": _this.state.token,
            },
          })
          .then((response) => {
            // console.log(response);
            toast.success("You now have full access to the course");
            if (response.data.data.recieptUrl) {
              if (window.screen.width > 1000) {
                _this.props.handleOverviewBtnClicked(true);
              }
              _this.setState({
                isDownloadModal: true,
                recieptUrl: response.data.data.recieptUrl,
              });
              _this.props.handleFormDetails();
            } else {
              if (window.screen.width > 1000) {
                _this.props.handleOverviewBtnClicked(true);
              }
              _this.setState({ isDownloadModal: true });
            }
            _this.props.handleFormDetails();
          })
          .catch((error) => {
            // console.log(">?>>>>>>>>>>>>>", error.response)
            console.error(error);
          });
      },
      modal: {
        ondismiss: function () {
          //that.removePromoCode(code,redeemId);
          console.log("MODAL DISMISSED *****");
          isCouponApplied
            ? _this.removeCouponWhenLogin(code, redeemId)
            : console.log("No coupon");
          isCouponApplied
            ? console.log("No coupon")
            : toast.info("Please purchase your course to get full access!");
        },
      },
      prefill: {
        contact: this.state.controls[1].value,
        email: this.state.controls[3].value,
      },
    };
    this.instance = new window.Razorpay(options);
    this.instance.open();
  };

  handleStateSearch = (e) => {
    this.setState(
      {
        searchTerm: e.target.value,
      },
      () => {
        this.handleArrayChange();
      }
    );
  };

  handleArrayChange = () => {
    let result =
      this.props &&
      this.props.stateArray &&
      this.props.stateArray.filter((el) =>
        el.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
      );
    this.setState({
      searchResult: result,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  handleOtpChange = (e) => {
    this.setState({ otpValue: e });
  };

  componentDidMount() {
    // this.startTimer();
  }

  handleStateSelectedDone = () => {
    let { controls, selectedStateVal } = this.state;
    controls = [...controls];
    controls[4].value = selectedStateVal;
    this.setState({
      controls,
      isCityClick: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.controls[0].value != prevState.controls[0].value ||
      this.state.controls[1].value != prevState.controls[1].value
    ) {
      if (
        checkValidity(
          this.state.controls[0].value,
          this.state.controls[0].validations
        ) ||
        checkValidity(
          this.state.controls[1].value,
          this.state.controls[1].validations
        )
      ) {
        // this._getEmail();
        this.setState({
          emailDisabled: false,
          nameDisabled: false,
          stateDisabled: false,
          otpValue: "",
        });
      }
    }
  }

  onChange(evt, index) {
    let { controls } = this.state;
    controls = [...controls];
    controls[index] = { ...controls[index] };
    controls[index].value = evt.target.value;
    // debugger;
    controls[index].touched = true;
    let formValid = true;
    for (let control of controls) {
      formValid =
        formValid && checkValidity(control.value, control.validations);
    }

    this.setState({ controls, formValid });
  }

  onBlurHandler(evt, index) {
    const control = this.state.controls[index];

    if (
      checkValidity(evt.target.value, control.validations) &&
      control.CT_EVENT
    ) {
      logEvent(control.CT_EVENT);
    }
  }

  _getEmail() {
    const promoHeaders = {
      "Api-Version": 11,
    };
    axios
      .get(`${AppConfig.BASE_URL}/v2/course/preview/user/email`, {
        headers: promoHeaders,
        params: {
          countryext: this.state.controls[0].value,
          mobile: this.state.controls[1].value,
          orgId: this.props.data.auxiliaryData.id,
        },
      })
      .then((res) => {
        let { controls } = this.state;
        controls[2].value = res.data.data.name;
        controls[3].value = res.data.data.email;

        if (res.data.data.isRegistered) {
          let { email, userId } = res.data.data;
          setCTProfile({
            Identity: userId,
            Email: email,
          });
          // console.log("if 1 isRegistered")
        }

        if (
          res.data.data.name != "" &&
          res.data.data.email != "" &&
          res.data.isStudent != 0
        ) {
          this.setState({
            controls,
            formValid: true,
          });
        }

        if (
          res.data.data.name != "" &&
          !res.data.data.email &&
          res.data.isStudent != 0 &&
          res.data.isRegistered != 0
        ) {
          this.setState({
            controls,
            nameDisabled: true,
          });
          return;
        }

        if (res.data.data.name.length != 0) {
          this.setState({
            nameDisabled: true,
          });
        }

        if (res.data.data.email.length != 0) {
          this.setState({
            emailDisabled: true,
          });
        }

        if (res.data.data.isStudent == 0) {
          this.setState({
            existingUserError: true,
            errorBuyNow: true,
            emailDisabled: true,
            nameDisabled: true,
            stateDisabled: true,
          });
          toast.error(res.data.message + ".Please enter a different number");
        } else {
          this.setState({
            controls,
            errorBuyNow: false,
            existingUserError: false,
            formValid: true,
          });
        }
        // this.setState({
        // 	errorBuyNow: false,
        // 	existingUserError :false
        // })
        // console.log(this.state.formValid, this.state.errorBuyNow, this.state.existingUserError, this.state.emailDisabled, this.state.nameDisabled)
      })
      .catch((error) => {
        // console.log(error,"-----------error3")
        toast.error("Something went wrong");
        this.setState({
          loading: false,
          error: "Internal server error",
          errorBuyNow: true,
        });
      });
  }

  // generateOtp(){

  // }
  scrollBottom = () => {
    let state = this.state;
    if (window.screen.availWidth < 1000)
      document.scrollingElement.scrollTop =
        document.scrollingElement.scrollHeight;
  };
  generateOtp = (resend) => {
    logEvent("SinglePayment_BuyNow_Click");
    const dateObj = new Date();
    let hashNumber = dateObj.getTime();

    let paramHeader = {
      headers: { "device-id": hashNumber },
    };

    this.setState({ isVerify: false, otpTimer: 30 });
    const promoHeaders = {
      "Api-Version": 11,
    };
    if (
      this.state.controls[0].value &&
      this.state.controls[1].value.length != 10
    ) {
      toast.error("Please enter 10 digit mobile number.");
      return;
    }
    axios
      .get(`${AppConfig.BASE_URL}/v2/course/preview/email/exists`, {
        headers: promoHeaders,
        params: {
          mobile: this.state.controls[1].value,
          email: this.state.controls[3].value,
          orgId: this.props.data.auxiliaryData.id,
          state: this.state.controls[4].value,
        },
      })
      .then((res) => {
        this.setState({ isVerify: true, otpValue: "" });

        this.startTimer();

        let params = {
          countryExt: this.state.controls[0].value,
          orgId: this.props.data.auxiliaryData.orgId,
          viaSms: "1",
          viaEmail: "1",
          mobile: this.state.controls[1].value,
          email: this.state.controls[3].value,
          state: this.state.controls[4].value,
        };
        if (this.state.controls[0].value !== "91") {
          params = {
            countryExt: this.state.controls[0].value,
            orgId: this.props.data.auxiliaryData.orgId,
            viaEmail: "1",
            viaSms: "1",
            email: this.state.controls[3].value,
            state: this.state.controls[4].value,
          };
        }
        axios
          .post(
            `${AppConfig.BASE_URL}/v2/course/preview/otp/${
              resend ? "retry" : "generate"
            }`,
            params,
            resend ? {} : paramHeader
          )
          .then((response) => {
            this.setState({
              sessionId: response.data.data.sessionId,
            });
            if (this.state.controls[0].value == "91") {
              toast.success(
                `OTP successfully sent to ${this.state.controls[0].value}${this.state.controls[1].value}`
              );
            } else {
              toast.success(
                `OTP successfully sent to ${this.state.controls[3].value}`
              );
            }
          })
          .catch((error) => {
            // console.log(error,"-----error3")
            if (error && error.response) {
              toast.error(error.response.data.message);
            }
          });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  render() {
    const {
      controls,
      isVerify,
      otpValue,
      isDownloadModal,
      emailDisabled,
      nameDisabled,
      stateDisabled,
      errorBuyNow,
      existingUserError,
      otpStyle,
      isCityClick,
      cityNameData,
      stateNameData,
      otpTimer,
      searchTerm,
      searchResult,
      // isCloseModal
    } = this.state;

    let {
      priceDetails,
      isCloseFormModal,
      isFormDetails,
      isCloseLockedContentFormModal,
      currentCouponCode,

      removeCouponApplied,
      toggleBothModals,
      formOpenViaCoupons,

      toggleCouponModalFromHome,
      isHideCouponDetails,
      setCouponAppliedSuccess,
      couponAppliedSuccess,
      handleOverviewBtnClicked,
      multipleCouponsCondition,
    } = this.props;
    console.log(multipleCouponsCondition, "++++multipleCouponsConditionForm");
    let showCouponsOnForm = sessionStorage.getItem("showCouponsOnForm");

    let stateData = searchTerm
      ? searchResult
      : this.props && this.props.stateArray;

    return (
      <div className={classes.SidePanel}>
        {!isCloseFormModal && (
          <Modal
            toggle={this.props.handleFormDetails}
            hidden={false}
            hideClose={true}
          >
            <FormatModal>
              <div className={classes.FormModal}>
                <div className={classes.SidePanelFormHeader}>
                  {formOpenViaCoupons ? (
                    <button
                      className={classes.SidePanelBackButton}
                      onClick={() => {
                        toggleBothModals();
                      }}
                    >
                      <img src={backButton} alt="" />
                    </button>
                  ) : (
                    ""
                  )}
                  <div
                    className={classes.SidePanelFormHeading}
                    style={{ fontWeight: "500" }}
                  >
                    Buy Course Now
                  </div>
                  <button
                    style={{
                      background: "#fff",
                      outline: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.props.closeFormModal(true);
                      if (this.props.handleOverviewBtnClicked) {
                        this.props.handleOverviewBtnClicked(false);
                      }
                    }}
                  >
                    <img
                      src={crossImg}
                      alt=""
                      style={{ paddingRight: "24px" }}
                    />
                  </button>
                </div>
                <div className={classes.SidePanelFormContents}>
                  <div className={classes.SidePanelForm}>
                    <form onSubmit={this.onSubmit} noValidate>
                      <div className={classes.SidePanelFormDetails}>
                        <img src={modalImage} alt="" />
                        <div className={classes.SidePanelFormDetailsContent}>
                          <div
                            className={classes.SidePanelFormDetailsContentRow1}
                          >
                            We need a few details
                          </div>
                          <div
                            className={classes.SidePanelFormDetailsContentRow2}
                          >
                            This helps us deliver the course to you so you can
                            start learning!
                          </div>
                        </div>
                      </div>

                      {this.props.couponAppliedSuccess && (
                        <CouponApplied
                          couponAppliedSuccess={couponAppliedSuccess}
                          setCouponAppliedSuccess={setCouponAppliedSuccess}
                          currentCouponCode={currentCouponCode}
                        />
                      )}

                      {controls.map((control, index) => {
                        let objectToRender1 = <React.Fragment />;
                        let objectToRender2 = <React.Fragment />;
                        // let objectToRender3 = <React.Fragment />;
                        if (control.name == "Mobile Number") {
                          return <React.Fragment />;
                        }
                        if (control.name === "State") {
                          // let countryExtList = countryExt.filter((a) => {
                          // 	if (a['countryISO']) return a;
                          // });
                          // console.log(countryExtList)
                          // countryExtList = _.pluck(countryExtList, 'countryISO')
                          let stateList =
                            stateData &&
                            stateData.map((state) => {
                              return {
                                label: state.name,
                                value: state.name,
                              };
                            });

                          return (
                            <div style={{ margin: "0 24px", zIndex: -1 }}>
                              <p style={{ margin: "5px" }}>State*</p>
                              {window.screen.width < 1000 ? (
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="text"
                                    className={classes.SelectStateMobile}
                                    onClick={this.handleCity}
                                    value={this.state.selectedStateVal}
                                    placeholder="Select a State"
                                  />
                                  <img
                                    src={nextArrow}
                                    alt=""
                                    className={classes.SelectStateMobileImage}
                                  />
                                </div>
                              ) : (
                                <Dropdown
                                  key={index}
                                  value={control.value}
                                  onChange={(e) => {
                                    // debugger
                                    this.onChange(
                                      { target: { value: e.value } },
                                      index
                                    );
                                    this.onBlurHandler(
                                      { target: { value: e.value } },
                                      index
                                    );
                                  }}
                                  // name={control.name}
                                  options={stateList}
                                  // defaultOption={countryExtList[0].countryISO}
                                  // value={this.state.controls[index].value}
                                  placeholder={control.placeholder}
                                />
                              )}
                            </div>
                          );
                        }
                        if (control.name === "Country Extension") {
                          let countryExtList = countryExt.filter((a) => {
                            if (a["countryISO"]) return a;
                          });
                          // console.log(countryExtList)
                          // countryExtList = _.pluck(countryExtList, 'countryISO')
                          countryExtList = countryExtList.map(
                            (singleCountry) => {
                              return {
                                label: singleCountry.countryISO,
                                value: singleCountry.countryISO,
                              };
                            }
                          );
                          // console.log(countryExtList);

                          objectToRender1 = (
                            <div style={{ marginBottom: "16px" }}>
                              <div className={classes.SidePanelDropdown}>
                                {" "}
                                Mobile Number*
                              </div>
                              <div
                                className={classes.CountryExtensionContainer}
                              >
                                <div style={{ width: "10%" }}>
                                  {
                                    //   <Dropdown
                                    //   key={index}
                                    //   value={this.state.controls[0].value}
                                    //   onChange={(e) => {
                                    //     // debugger
                                    //     this.onChange(
                                    //       { target: { value: e.value } },
                                    //       index
                                    //     );
                                    //     this.onBlurHandler(
                                    //       { target: { value: e.value } },
                                    //       index
                                    //     );
                                    //   }}
                                    //   // name={control.name}
                                    //   options={countryExtList}
                                    //   defaultOption={countryExtList[0].countryISO}
                                    //   // value={this.state.controls[index].value}
                                    //   placeholder="Select"
                                    //   // placeholder="Country Extension"
                                    // />
                                    <div
                                      className={classes.CountryMobileExtension}
                                    >
                                      +91
                                    </div>
                                  }
                                </div>
                                <p className={classes.Hyphen}> - </p>
                                <Input
                                  ref={this.textInput}
                                  key={index + 1}
                                  onChange={(evt) =>
                                    this.onChange(evt, index + 1)
                                  }
                                  value={controls[index + 1].value}
                                  placeholder={controls[index + 1].placeholder}
                                  type={controls[index + 1].type}
                                  validations={controls[index + 1].validations}
                                  errorMessage={
                                    controls[index + 1].errorMessage
                                  }
                                  touched={controls[index + 1].touched}
                                  onBlur={(e) => {
                                    this.onBlurHandler(
                                      { target: { value: e.target.value } },
                                      index + 1
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          );
                        } else {
                          let countryExtList = countryExt.filter((a) => {
                            if (a["countryISO"]) return a;
                          });
                          // console.log(countryExtList)
                          // countryExtList = _.pluck(countryExtList, 'countryISO')
                          countryExtList = countryExtList.map(
                            (singleCountry) => {
                              return {
                                label: singleCountry.countryISO,
                                value: singleCountry.countryISO,
                              };
                            }
                          );
                          objectToRender2 = (
                            <div>
                              <Input
                                key={index}
                                onChange={(evt) => this.onChange(evt, index)}
                                name={control.name}
                                value={control.value}
                                placeholder={control.placeholder}
                                type={control.type}
                                validations={control.validations}
                                errorMessage={control.errorMessage}
                                touched={control.touched}
                                nameDisabled={nameDisabled}
                                emailDisabled={emailDisabled}
                                stateDisabled={stateDisabled}
                                onBlur={(e) =>
                                  this.onBlurHandler(
                                    { target: { value: e.target.value } },
                                    index
                                  )
                                }
                              />
                            </div>
                          );
                        }

                        return (
                          <div className={classes.divFlex}>
                            {objectToRender1}
                            {objectToRender2}
                            {/* {objectToRender3} */}
                          </div>
                        );
                      })}
                    </form>
                  </div>

                  <div className={classes.Partition}></div>

                  {showCouponsOnForm ? (
                    <div className={classes.AppliedCoupon}>
                      <div className={classes.AppliedCouponHeading}>
                        Coupons
                      </div>

                      {currentCouponCode && currentCouponCode.code ? (
                        //WHEN COUPON IS APPLIED
                        <div className={classes.CouponSuccessful}>
                          <div className={classes.AppliedCouponDetails}>
                            <div className={classes.AppliedCouponCode}>
                              {<img src={CouponSuccess} alt="" />}
                              {"Code "}
                              {currentCouponCode && currentCouponCode.code}
                              {" Applied"}
                            </div>
                            <div className={classes.AppliedCouponLabel}>
                              {currentCouponCode && currentCouponCode.label}
                            </div>
                          </div>

                          <div
                            className={classes.RemoveCoupon}
                            onClick={() => {
                              removeCouponApplied();
                            }}
                          >
                            REMOVE
                          </div>
                        </div>
                      ) : (
                        //WHEN NO COUPON IS APPLIED
                        <div className={classes.CouponSuccessful}>
                          <div className={classes.SelectACoupon}>
                            <img src={NoCouponTag} alt="" />
                            <div>
                              {multipleCouponsCondition == 3
                                ? "Apply coupon code"
                                : "Select a coupon code"}{" "}
                            </div>
                          </div>

                          <div
                            className={classes.ViewAllButton}
                            onClick={() => {
                              toggleCouponModalFromHome();
                              handleOverviewBtnClicked(true);
                            }}
                          >
                            {multipleCouponsCondition == 3
                              ? "APPLY NOW"
                              : "VIEW ALL"}{" "}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={classes.ModalPriceDetails}>
                    <div className={classes.ModalPriceDetailsHeading}>
                      Price Details
                    </div>
                    {priceDetails &&
                      priceDetails.map((item, i) => {
                        return (
                          <div
                            className={classes.ModalPriceDetailsItem}
                            key={i}
                          >
                            <div className={classes.ModalPriceDetailsItemKey}>
                              {item && item.label}
                            </div>
                            <div className={classes.ModalPriceDetailsItemValue}>
                              {item && item.value}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className={classes.SubmitButtonContainer}>
                    {window.screen.width < 700 ? (
                      <div className={classes.SubmitButtonContainerText}>
                        <img src={securePaymentImg} alt="" />
                        <div>Secure payment with Razorpay</div>
                      </div>
                    ) : (
                      ""
                    )}
                    <button
                      // disabled={errorBuyNow || existingUserError}
                      className={
                        classes.SubmitButton +
                        " " +
                        (!this.state.formValid ? classes.disableButton : "")
                      }
                      onClick={
                        this.state.formValid
                          ? () => {
                              this.generateOtp(0);
                            }
                          : this.scrollBottom
                      }
                    >
                      {/*Buy now &nbsp;<symbol >₹</symbol > {this.props.data.courseDetail.totalPayableAmount}/-*/}
                      {window.screen.width < 700
                        ? "Verify Mobile Number via OTP"
                        : "Verify via OTP"}
                    </button>
                  </div>
                </div>
              </div>
            </FormatModal>
          </Modal>
        )}

        <Modal toggle={this.handleModal} hidden={!isVerify}>
          <FormatModal>
            {/*<div className={classes.OtpModal}>
							<img src={verified} />
							<p className={classes.OtpModal_heading}>Verify Your Mobile Number</p>

							<p className={classes.OtpModal_text}>Enter OTP sent to your Mobile Number</p>
							<p className={classes.OtpModal_mobileNumber}>{this.state.controls[1].value}</p>

							<input type="number" maxLength='4' placeholder="Enter OTP here" className={classes.OtpModal_input}
								value={otpValue} onChange={this.handleOtpInput} ></input>

							<button onClick={this.handleVerifyOtp} disabled={(this.state.otpValue + "").length !== 4} className={classes.OtpModal_button + " " + (!this.state.formValid ? classes.disableButton : "")}>
								Verify
							</button>
							</div>*/}
            <div className={classes.OtpModalContainer}>
              <div className={classes.OtpModalContainerHeading}>
                Verify Mobile Number
              </div>
              <div className={classes.OtpModalContentContainer}>
                <div className={classes.OtpModalContentText}>
                  We’ve sent an OTP to your mobile number
                </div>
                <div className={classes.OtpModalContentMobileField}>
                  <div className={classes.OtpModalContentMobile}>
                    <div className={classes.OtpModalContentMobileCode}>
                      {this.state.controls[0].value}
                    </div>
                    <div className={classes.OtpModalContentMobileNumber}>
                      {this.state.controls[1].value}
                    </div>
                  </div>
                  <button
                    className={classes.OtpModalContentMobileNumberBtn}
                    onClick={this.handleModal}
                  >
                    Change
                  </button>
                </div>
                <div className={classes.EnterOtpText}>Enter OTP here</div>
                <div style={{ marginLeft: "24px" }}>
                  <OtpInput
                    onChange={this.handleOtpChange}
                    numInputs={4}
                    value={this.state.otpValue}
                    isInputNum={true}
                    separator={
                      <span
                        style={{
                          padding: "0px 12px",
                        }}
                      />
                    }
                    inputStyle={otpStyle}
                  />
                </div>

                <div className={classes.waitingForOtp}>
                  {otpTimer !== 0 && (
                    <div>
                      Waiting for OTP 00:{otpTimer < 10 ? "0" : ""}
                      {otpTimer}
                    </div>
                  )}
                  {otpTimer === 0 && (
                    <div
                      // className={classes.waitingForOtp}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img src={msgIcon} alt="" />
                      <div
                        style={{ marginLeft: "8px", cursor: "pointer" }}
                        onClick={() => {
                          this.generateOtp(1);
                        }}
                      >
                        Resend OTP via SMS
                      </div>
                    </div>
                  )}
                </div>

                <div className={classes.SubmitButtonOtpContainer}>
                  {window.screen.width < 700 ? (
                    <div className={classes.SubmitButtonOtpContainerText}>
                      <img src={securePaymentImg} alt="" />
                      <div>Secure payment with Razorpay</div>
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    disabled={errorBuyNow || existingUserError}
                    className={
                      classes.OtpModal_button +
                      " " +
                      (!this.state.formValid ? classes.disableButton : "")
                    }
                    onClick={this.handleVerifyOtp}
                    // disabled={(this.state.otpValue + "").length !== 4}
                    // className={
                    //   classes.OtpModal_button +
                    //   " " +
                    //   (!this.state.formValid ? classes.disableButton : "")
                    // }
                  >
                    {/*Buy now &nbsp;<symbol >₹</symbol > {this.props.data.courseDetail.totalPayableAmount}/-*/}
                    Proceed to buy
                  </button>
                </div>
              </div>
            </div>
          </FormatModal>
        </Modal>

        <Modal
          toggle={this.handleCityModal}
          hidden={!isCityClick}
          className={classes.selectStateModalMain}
          // hideClose={true}
        >
          <FormatModal>
            <div className={classes.selectStateModal}>
              <div className={classes.selectStateModalHeading}>
                Select State
                {/*<button style={{ background: "#fff", outline: "none", border: "none" }} onClick={this.closeFormModal}>
                  <img src={crossImg} alt="" />
                  </button>*/}
              </div>
              <div className={classes.selectStateModalContent}>
                <div className={classes.selectStateModalContentTopText}>
                  Please select your residing state
                </div>
                <div className={classes.selectStateModalContentInputField}>
                  <input
                    type="text"
                    value={searchTerm}
                    className={classes.selectStateModalContentInputBox}
                    placeholder="Select your state"
                    onChange={this.handleStateSearch}
                  />
                  <img
                    src={RadioBtnImg}
                    alt=""
                    className={classes.selectStateModalContentInputImg}
                  />
                </div>
                <div className={classes.selectStateModalCityListHeading}>
                  All States/Union Territories
                </div>
                <div className={classes.selectStateModalCityLists}>
                  {stateData &&
                    stateData.map((dt, index) => {
                      return (
                        <div className={classes.selectStateModalCityItem}>
                          <div className={classes.selectStateModalCityItemName}>
                            {dt.name}
                          </div>
                          <input
                            type="radio"
                            name="radio"
                            value={dt.name}
                            onClick={(e) => {
                              this.setState({
                                searchTerm: e.target.value,
                                selectedStateVal: dt.name,
                              });
                              this.onChange(e, 4);
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className={classes.selectStateModalFooter}>
                <button
                  onClick={this.handleStateSelectedDone}
                  className={classes.selectStateModalBtn}
                >
                  Done
                </button>
              </div>
            </div>
          </FormatModal>
        </Modal>

        <Modal toggle={this.handleDownloadModal} hidden={!isDownloadModal}>
          <FormatModal>
            <div className={classes.DownloadModal_content}>
              {window.screen.width < 700 ? (
                <img
                  src={
                    "https://cp-assets-public.s3.ap-south-1.amazonaws.com/classplus-websites/common/images/succesGif.gif"
                  }
                />
              ) : (
                <img
                  src={
                    "https://cp-assets-public.s3.ap-south-1.amazonaws.com/classplus-websites/common/images/succesGif.gif"
                  }
                />
              )}
              <p className={classes.DownloadModal_heading}>Success !</p>
              <p className={classes.DownloadModal_text}>
                You have successfully purchased{" "}
                {this.props.data &&
                  this.props.data.auxiliaryData &&
                  this.props.data.auxiliaryData.courseName}{" "}
                .Download the app to start learning now.
              </p>
              <a
                href={
                  this.props.data &&
                  this.props.data.details &&
                  this.props.data.details.appLink
                }
                className={classes.DownloadLink}
              >
                <button
                  onClick={() => {
                    window.open(
                      this.props.data &&
                        this.props.data.details &&
                        this.props.data.details.appLink
                    );
                  }}
                  className={classes.DownloadModal_button}
                >
                  Download App
                </button>
              </a>

              {this.state.recieptUrl && (
                <button
                  onClick={() => {
                    window.open(this.state.recieptUrl);
                  }}
                  className={classes.DownloadModal_ReceiptButton}
                >
                  Download Receipt
                </button>
              )}
            </div>
          </FormatModal>
        </Modal>
      </div>
    );
  }
}
export default SidePanel;
