import React from "react";

export default importComponent => {
	return class extends React.Component {
		state = {
			Component: null
		};

		componentDidMount() {
			importComponent.then(cmp =>
				this.setState({ Component: cmp.default })
			);
		}

		render() {
			return this.state.Component ? (
				<this.state.Component {...this.props} />
			) : (
				<div>Loading...</div>
			);
		}
	};
};
