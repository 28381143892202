import React from 'react';
import classes from "./StudentViewed.module.css"
import studyMaterial1 from "../../images/StudentsViewed/studyMaterial1.svg"
import { withRouter } from 'react-router-dom';



class StudentViewed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
    }

    handleCourseOpen = (item) => {
        window.open(item.singlePaymentLink)
    }

    render() {
        const { similarCourseArr } = this.props

        return (
            <div className={classes.StudentViewed}>
                <div className={classes.StudentViewedHeading}>Students also viewed</div>
                <div className={classes.StudentViewedContentList}>

                    {similarCourseArr && similarCourseArr.slice(0, 3).map((item, index) => {

                        return (
                            <div key={index} className={classes.StudentViewedContent} onClick={() => this.handleCourseOpen(item)} style={index + 1 == similarCourseArr.length ? {} : { borderBottom: "1px solid #E5E5E5" }}>
                                <div className={classes.StudentViewedContentImg}>
                                    <img alt="" src={item.imageUrl || 'https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/cards-icon/default_course.png'} style={{ width: "100%", height: "100%" }} />
                                    {item.tag && <label className={classes.imageTag}>{item.tag.text}</label>}
                                </div>
                                <div className={classes.StudentViewedContentText}>
                                    <div className={classes.StudentViewedContentRow1}>{item.name}</div>
                                    <div className={classes.StudentViewedContentRow2}>
                                        <div className={classes.StudentViewedContentRupees}>₹ {item.finalPrice}</div>
                                        {item.discount > 0 ? <> <div className={classes.StudentViewedContentDiscount}>₹ {item.price}</div>
                                            <div className={classes.StudentViewedContentOffer}>{((item.discount / item.price) * 100).toFixed(2)}% OFF</div> </> : ''}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }

                    {/* <div className={classes.StudentViewedContent}>
                        <div className={classes.StudentViewedContentImg}>
                            <img alt="" src={studyMaterial1} />
                            <label className={classes.imageTag}>Trending</label>
                        </div>
                        <div className={classes.StudentViewedContentText}>
                            <div className={classes.StudentViewedContentRow1}>Complete Study Material for Class CS 5th Semester</div>
                            <div className={classes.StudentViewedContentRow2}>
                                <div className={classes.StudentViewedContentRupees}>₹ 5,000</div>
                                <div className={classes.StudentViewedContentDiscount}>₹2,500</div>
                                <div className={classes.StudentViewedContentOffer}>50% OFF</div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.StudentViewedContent}>
                        <div className={classes.StudentViewedContentImg}>
                            <img alt="" src={studyMaterial1} />
                            <label className={classes.imageTag}>Trending</label>
                        </div>
                        <div className={classes.StudentViewedContentText}>
                            <div className={classes.StudentViewedContentRow1}>Complete Study Material for Class CS 5th Semester</div>
                            <div className={classes.StudentViewedContentRow2}>
                                <div className={classes.StudentViewedContentRupees}>₹ 5,000</div>
                                <div className={classes.StudentViewedContentDiscount}>₹2,500</div>
                                <div className={classes.StudentViewedContentOffer}>50% OFF</div>
                            </div>
                        </div>
                    </div> */}
                </div>
                {(similarCourseArr && similarCourseArr.length>3) ?  <button className={classes.StudentViewedFooter} onClick={() => {
                    this.props.history.push('/MoreCourses');
                }}>See more courses</button>:""}

            </div>
        )
    }
}

export default withRouter(StudentViewed);