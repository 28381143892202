import React, { Component } from 'react';
import classes from './ShareModal.module.css';
import Modal from "../UI/Modal/Modal";
import FormatModal from "../UI/FormatModal/FormatModal";
import fbImg from "../images/fbImg.svg"
import twiiterImg from "../images/twiiterImg.svg"
import whatsappImg from "../images/whatsappImg.svg"

class ShareModal extends Component {
    constructor() {
        super();
        this.state = {
            isCopied: false,
        };
    }

    copyCodeToClipboard = () => {
        const el = this.textArea;
        el.select();
        document.execCommand("copy");
        this.setState({ isCopied: true });
    };

    render() {
        let { isShareBtn, shareUrl } = this.props;
        let { isCopied } = this.state;
        return (
            <Modal toggle={this.props.handleShareBtn} hidden={!isShareBtn} >
                <FormatModal>
                    <div className={classes.shareModal}>
                        <div className={classes.shareModalHeading}>Share Via</div>
                        <div className={classes.shareModalContainer}>
                            <div className={classes.shareModalShareList}>
                                <a href={`https://twitter.com/intent/tweet?text=${shareUrl}`} target="_blank"><img src={twiiterImg} alt="" className={classes.shareModalShareImg} /></a>
                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`} target="_blank"><img src={fbImg} alt="" className={classes.shareModalShareImg} /></a>
                                <a href={`https://api.whatsapp.com/send?text=${shareUrl}`} target="_blank"><img src={whatsappImg} alt="" className={classes.shareModalShareImg} /></a>
                            </div>
                            <div className={classes.shareModalCopyInput}>
                                <input ref={(textarea) => (this.textArea = textarea)} className={classes.shareModalCopyInputBox} type="text" value={shareUrl} />
                                <button className={classes.shareModalCopyBtn} onClick={() => this.copyCodeToClipboard()}>Copy</button>
                            </div>
                            {isCopied && <div className={classes.copySuccess}>Copied to Clipboard</div>}
                        </div>
                    </div>
                </FormatModal>
            </Modal>

        );
    }
}
export default ShareModal;
