import React from "react";

import SuccessTick from "../../images/SuccessTick.gif";

function CouponApplied({ setCouponAppliedSuccess, currentCouponCode }) {
  return (
    <div className="couponAppliedWrapper">
      <div className="couponAppliedModal">
        <div className="couponAppliedRow1">
          <img style={{ width: "56px" }} src={SuccessTick} alt="" />
        </div>
        <div className="couponAppliedRow2">
          <div className="couponAppliedText1">Coupon Applied Successfully</div>
          <div className="couponAppliedText2">
            Your coupon{" "}
            <span style={{ fontWeight: "bolder" }}>
              {currentCouponCode.code}
            </span>{" "}
            <br /> has been successfully applied
          </div>
        </div>
        <div
          className="couponAppliedDone"
          onClick={() => {
            setCouponAppliedSuccess();
          }}
        >
          DONE
        </div>
      </div>
    </div>
  );
}

export default CouponApplied;
