import React from "react";
import classes from "./MoreCourses.module.css";
import backBtn from "../images/MoreCourses/backBtn.svg";
import mobileCourseImg from "../images/MoreCourses/mobileCourse.svg";
import backBtnDesktop from "../images/MoreCourses/backBtnDesktop.svg";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { getShortenedSubstring } from "../utils";

class MoreCourses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MoreCoursesData: [],
      loadMoreFlag: true,
      offsetCounter: 0,
      courseData: [],
    };
  }

  componentDidMount() {
    this.props.fetchMoreCourses(20, 0);
    window.scrollTo(0, 0);
  }

  loadMoreCallback = (page) => {
    const { courseTotalCount } = this.props;
    const { courseData } = this.state;

    if (courseTotalCount && courseTotalCount == courseData.length) {
      this.setState({
        loadMoreFlag: false,
      });
      return;
    } else {
      this.props.fetchMoreCourses(20, this.state.offsetCounter);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.courseLoading && !this.props.courseLoading) {
      let concatData = [
        ...this.state.courseData,
        ...this.props.similarCourseArr,
      ];

      this.setState({
        courseData: concatData,
        offsetCounter: this.state.offsetCounter + 20,
        loadMoreFlag: concatData.length != this.props.courseTotalCount,
      });
    }
  }

  handleCourseOpen = (item) => {
    window.open(item.singlePaymentLink);
  };

  render() {
    let { loadMoreFlag, courseData } = this.state;
    const { orgName } = this.props;
    return (
      <div className={classes.MoreCourses}>
        <div
          className={classes.MoreCoursesHeader}
          onClick={() => {
            this.props.history.goBack();
          }}
        >
          <div
            className={classes.MoreCoursesBackBtn}
            // onClick={() => {
            // 	this.props.history.push('/');
            // }}
          >
            <img
              src={window.screen.width < 700 ? backBtn : backBtnDesktop}
              alt=""
            />
          </div>
          <div className={classes.MoreCoursesHeading}>
            More Courses by {orgName}
          </div>
        </div>

        <InfiniteScroll
          dataLength={courseData.length}
          next={this.loadMoreCallback}
          hasMore={loadMoreFlag}
          loader={
            <p style={{ textAlign: "center" }}>
              <h4>Loading...</h4>
            </p>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          style={{ marginTop: "64px" }}
        >
          <div className={classes.MoreCoursesContentList}>
            {courseData.map((CoursesData, index) => {
              return (
                <div
                  className={classes.MoreCoursesContent}
                  key={index}
                  onClick={() => this.handleCourseOpen(CoursesData)}
                >
                  <div className={classes.MoreCoursesContentImg}>
                    <img
                      alt=""
                      src={
                        CoursesData.imageUrl ||
                        "https://cp-assets-public.s3.ap-south-1.amazonaws.com/cams/cards-icon/default_course.png"
                      }
                      className={classes.MoreCoursesContentImage}
                    />
                    {CoursesData.tag && (
                      <label className={classes.imageTag}>
                        {CoursesData.tag.text}
                      </label>
                    )}
                  </div>
                  <div className={classes.MoreCoursesContentText}>
                    <div className={classes.MoreCoursesContentRow1}>
                      {getShortenedSubstring(CoursesData.name, 40)}
                    </div>
                    <div className={classes.MoreCoursesContentRow2}>
                      <div className={classes.MoreCoursesContentRupees}>
                        ₹{CoursesData.finalPrice}
                      </div>
                      {CoursesData.discount > 0 ? (
                        <>
                          <div className={classes.MoreCoursesContentDiscount}>
                            ₹{CoursesData.price}
                          </div>
                          <div className={classes.MoreCoursesContentOffer}>
                            {(
                              (CoursesData.discount / CoursesData.price) *
                              100
                            ).toFixed(2)}
                            % OFF
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

export default withRouter(MoreCourses);
