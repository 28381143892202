import React from 'react';
import classes from './MobileCarousel.module.css';
import img1 from '../../images/saleCarousel.svg';
import img2 from '../../images/purchaseCarousel.svg';
import img3 from '../../images/discountCarousel.svg';
import Slider from 'react-slick';
import Carousel from '../../UI/Carousel/Carousel';
class MobileCarousel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			carouselData: [
				{
					icon: img1,
					text: 'Harsh and 24 others purchased last week'
				},
				{
					icon: img2,
					text: 'Course expires on 22 Sep’20. Buy now!'
				},
				{
					icon: img3,
					text: 'Limited period discount. Buy now and save ₹ 1,200'
				}
			]
		};
	}

	componentDidMount() { }

	render() {
		let { carouselData } = this.state;
		const { emblemList } = this.props;
		const settings = {
			dots: true,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			speed: 1000,
			autoplaySpeed: 5000,
			cssEase: 'linear',
			pauseOnHover: true,
			arrows: false
		};
		return (
			<div className={classes.container}>
				<Slider
					{...settings}
					style={{
						margin: '0 0px 32px 0px',
						background: '#FCF4DB',
						border: '1px solid #F2C94C',
						borderRadius: '4px'
					}}
				>
					{emblemList &&
						emblemList.map((dt, index) => {
							return (
								<div className={classes.tag} key={index}>
									<img src={dt.icon} alt="" style={{ marginRight: '8px', width: '16px' }} />
									<p style={{ margin: '0' }}>{dt.text}</p>
								</div>
							);
						})}
				</Slider>
				{/* <Carousel
					// loading={props.loading}
					listStyle={classes.marginZero}
					isBannerType
					elements={carouselData.map((dt, index) => {
						return (
							<div className={classes.tag} key={index}>
								<img src={dt.image} alt="" style={{ marginRight: '8px' }} />
								<p style={{ margin: '0' }}>{dt.name}</p>
							</div>
						);
					})}
					width="100%"
					elementWidth={window.screen.width}
					activeSlide={0}
				/> */}
			</div>
		);
	}
}

export default MobileCarousel;
