import AppConfig from "../../AppConfig";

function isUrl(fileString) {
  return fileString.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
  );
}

export default function (fileString, token) {
  // let data = sessionStorage.getItem('data');
  // let dataObj = JSON.parse(data);
  // let {token} = dataObj;
  let fileUrl = fileString;
  if (!isUrl(fileString)) {
    fileUrl = `${AppConfig.API_S3_UPLOAD_URL}/dev3/v2/users/download/file?key=${fileString}&x-access-token=${token}`;
  }
  return fileUrl;
}
