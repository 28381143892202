import React from "react";
import classes from "./PreviewCourse.module.css";
import PreviewCourseImg from "../../images/PreviewCourse/PreviewCourseImgMobile.svg";
import courseTime from "../../images/PreviewCourse/courseTime.svg";
import { withRouter } from "react-router-dom";
import back from "../../images/MoreCourses/backBtnDesktop.svg";
import PreviewLockImg from "../../images/lockPreview.svg";
import PreviewLockBackgroundImg from "../../images/PreviewCourse/PreviewLockBackgroundImg.svg";
import png from "../../images/format/png.svg";
import pdf from "../../images/format/pdf.svg";
import jpg from "../../images/format/jpg.svg";
import gif from "../../images/format/gif.svg";
import ppt from "../../images/format/ppt.svg";
import svg from "../../images/format/svg.svg";
import doc from "../../images/format/doc.svg";
import SVGClose from "../ContentListCard/images/SVGClose.svg";
import { get } from "lodash";
import AsyncComponent from "../ContentListCard/AsyncComponent";
import {
  RESOURCE_TYPES,
  RESOURCE_FILETYPES,
} from "../ContentListCard/constant";
// import SVGTest from "../ContentListCard/images/SVGTest.svg";
import SVGTest from "../ContentListCard/images/testIcon.svg";
import SVGDefaultVideo from "../ContentListCard/images/defaultvideo.svg";
// import SVGPdf from "../ContentListCard/images/SVGPdf.svg";
import SVGPdf from "../ContentListCard/images/pdfIcon.svg";
import otherDocument from "../ContentListCard/images/other-document.svg";
import NewLockPurchase from "../ContentListCard/images/newlock.svg";
import getS3DownloadUrl from "../ContentListCard/signedUrl";
import SVGIframe from "../ContentListCard/images/SVGPlayButton3.svg";
import Backdrop from "../../UI/Backdrop/Backdrop2";

const Player = AsyncComponent(import("../ContentListCard/Player"));

class PreviewCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PreviewCourseData: [
        {
          image: PreviewCourseImg,
          name: "Introduction to Computer Science Sem 3 Course",
          subText: "Part 1: Introduction...",
          time: "32:15",
        },
        {
          image: PreviewCourseImg,
          name: "Introduction to Data Structures",
          subText: "Part 1: Introduction...",
          time: "32:15",
        },
        // {
        // 	image: PreviewCourseImg,
        // 	name: 'Making a linked list in C',
        // 	subText: ' Part 2: Data Structu...',
        // 	time: '32:15'
        // }
      ],
      imageFormat: {
        png: png,
        jpg: jpg,
        svg: svg,
        pdf: pdf,
        ppt: ppt,
        gif: gif,
        doc: doc,
      },
    };
  }

  componentDidMount() {
    if (window.innerWidth < 1024) {
      window.scrollTo(0, 0);
    } else if (this.props.selectedTab == 0) {
      window.scrollTo(0, 0);
    } else if (this.props.selectedTab == 1) {
      window.scrollTo(0, 240);
    }
  }

  handleIframeClose = () => {
    this.setState({ modalIframeOpen: false, modalIframeId: null });
  };

  getIconImage(item) {
    const itemType = item.contentType;
    let icon = null;
    switch (true) {
      case RESOURCE_TYPES.video == itemType:
        icon = item.thumbnailUrl;
        break;
      case RESOURCE_TYPES.document == itemType &&
        RESOURCE_FILETYPES[RESOURCE_TYPES.document].ext.indexOf(
          get(item, "format", "pdf")
        ) != -1:
        icon = SVGPdf;
        break;
      case RESOURCE_TYPES.document == itemType:
        icon =
          // (item.url && getS3DownloadUrl(item.url, this.token)) ||
          SVGPdf;

        break;
      case RESOURCE_TYPES.test == itemType:
        icon = SVGTest;
        break;
      default:
        icon = SVGDefaultVideo;
    }
    return icon;
  }

  showPlayer = () => {
    const { item } = this.state;
    return this.state.jwplayerVideo && this.state.modalIframeId === item.id ? (
      <div className="iframeContent">
        <div style={{ position: "relative" }}>
          <Player
            item={item}
            handleOpenWatchExhaustedModal={this.handleOpenWatchExhaustedModal}
            handleIframeClose={this.handleIframeClose}
            student={this.state.student}
            isExhaustedDisabled={true}
          />
          <img
            src={SVGClose}
            className={classes.videoCrossImg}
            // style={{ position: "absolute", right: "25%", top: "25%" }}
            onClick={() => {
              this.handleIframeClose(true);
            }}
          />
          <Backdrop
            show={
              this.state.jwplayerVideo && this.state.modalIframeId === item.id
            }
            toggle={this.handleIframeClose}
            backgroundColor={false}
          />
        </div>
      </div>
    ) : (
      ""
    );
  };

  handleOnClick = (item) => {
    const itemType = item.contentType;

    this.setState({
      item,
    });
    switch (itemType) {
      case RESOURCE_TYPES.video:
        if (
          item.isWebVideoAllowed &&
          (item.videoType == "jw-hosted" || item.videoType == "exo-hosted")
        ) {
          this.setState({
            jwplayerVideo: true,
            modalIframeId: item.id,
          });
        }
        break;

      case RESOURCE_TYPES.document:
        if (item.url) {
          window.open(getS3DownloadUrl(item.url, this.token));
        }
        break;
    }
  };

  render() {
    let { PreviewCourseData, imageFormat } = this.state;
    let { freeContent, selectedTab, freeContentSection } = this.props;
    let previewData = [];

    if (freeContent && freeContent.length) {
      previewData =
        window.location.href.match("/Preview") || selectedTab == 1
          ? freeContent
          : freeContent.slice(0, 3);
    }

    return (
      <React.Fragment>
        {/* {window.location.href.match("/Preview") ? (
          <div className={classes.header}>
            <button
              className={classes.backBtn}
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              <img src={back} alt="" style={{ marginRight: "24px" }} />
            </button>
            Course Contents
          </div>
        ) : null} */}
        <div className={classes.PreviewCourse}>
          <div className={classes.PreviewCourseHeading}>Preview Course</div>
          <div className={classes.PreviewCourseList}>
            {previewData.map((data, index) => {
              let imgSrc = imageFormat[data.format];
              return (
                <div
                  className={classes.PreviewCourseItem}
                  key={index}
                  onClick={() => this.handleOnClick(data)}
                >
                  <div
                    className={classes.PreviewCourseItemImage}
                    style={{ position: "relative" }}
                  >
                    <img
                      src={this.getIconImage(data)}
                      alt=""
                      className={classes.PreviewCourseItemImg}
                    />
                    {data.contentType == 2 &&
                    data.isWebVideoAllowed &&
                    (data.videoType == "jw-hosted" ||
                      data.videoType == "exo-hosted") ? (
                      <img
                        src={SVGIframe}
                        alt="Play Video"
                        className="IframePlaySvg2"
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      ""
                    )}
                    {/* <div className={classes.PreviewCourseItemImageTime}>
											<img src={courseTime} alt="" />
											<label>{data.time}</label>
										</div> */}
                  </div>
                  <div className={classes.PreviewCourseItemContent}>
                    <div className={classes.PreviewCourseItemContentRow1}>
                      {data.name}
                    </div>
                    <div className={classes.PreviewCourseItemContentRow2}>
                      <span className={classes.KeyText}>Folder:</span>{" "}
                      {data.description}
                    </div>
                  </div>
                </div>
              );
            })}
            {previewData.length < 3 ? (
              <div className={classes.PreviewCourseItem}>
                <div
                  className={classes.PreviewCourseItemImage}
                  style={{ height: "auto", maxHeight: "unset" }}
                >
                  <img
                    src={PreviewLockBackgroundImg}
                    alt=""
                    className={classes.PreviewCourseLockedImg}
                  />
                  <div className={classes.PreviewCourseLockImage}>
                    <img src={PreviewLockImg} alt="" />
                  </div>
                </div>
                <div className={classes.PreviewCourseItemContent}>
                  <div className={classes.PreviewCourseItemContentRow1}>
                    Buy course to unlock all content
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {window.location.href.match("/Preview") || selectedTab == 1 ? null : (
            <button
              className={classes.PreviewCourseFooter}
              onClick={() => {
                window.screen.width < 1000
                  ? this.props.history.push("/Preview")
                  : this.props.handleViewAllPreviewContent(true);
              }}
            >
              View all content
            </button>
          )}
        </div>
        {this.showPlayer()}
      </React.Fragment>
    );
  }
}

export default withRouter(PreviewCourse);
