import React from "react";
import classes from "./ContentList.module.css";
import { getQuery } from "../../utils";
import AppConfig from "../../AppConfig";
import axios from "axios";
import { withRouter } from "react-router-dom";
import ContentLockedModal from "../../ContentLockedModal/ContentLockedModal";
import PreviewLockImg from "../../images/lockIcon.svg";
import back from "../../images/backBtn.svg";
// import SVGPdf from "./images/SVGPdf.svg";
import otherDocument from "./images/other-document.svg";
import folderlarge from "./images/folderlarge.svg";
import SVGIframe from "./images/SVGPlayButton3.svg";
// import SVGTest from "./images/SVGTest.svg";
import SVGTest from "./images/testIcon.svg";
import SVGPdf from "./images/pdfIcon.svg";
import SVGDefaultVideo from "./images/defaultvideo.svg";
import { get } from "lodash";
import playIcon from "../../images/ContentList/playIcon.svg";
import stringUtils from "./utils";
import FreeContent from "./freeContent";
import { Modal, Icon, Button } from "semantic-ui-react";
import getS3DownloadUrl from "./signedUrl";
import vidIcon from "../../images/vidIco.svg";
import forward from "../../images/forward.svg";
import {
  RESOURCE_TYPES,
  COURSE_SECTIONS,
  RESOURCE_FILETYPES,
} from "./constant";
import AsyncComponent from "./AsyncComponent";
import RestrictionDetails from "./restrictionDetails";
import SVGClose from "./images/SVGClose.svg";
import LockPurchase from "./images/lockPurchase.png";
import NewLockPurchase from "./images/newlock.svg";
import ContentRestrict from "./images/contentRestrict.svg";
import Backdrop from "../../UI/Backdrop/Backdrop2";

const Player = AsyncComponent(import("./Player"));

class ContentList extends React.Component {
  constructor(props) {
    super(props);
    // this.pushNavFolder = [];
    this.token = "";

    this.state = {
      isViewClicked: false,
      ContentData: [],
      isContentLocked: false,
      isFolderClicked: false,
      modalIframeOpen: false,
      pushNavFolder: [],
    };
  }

  handleContentLockedModal = () => {
    this.setState({ isContentLocked: false });
  };

  componentDidMount() {
    this.fetchCourseContentData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTab != this.props.selectedTab) {
      if (window.innerWidth < 1024) {
        window.scrollTo(0, 0);
      } else if (this.props.selectedTab == 0) {
        window.scrollTo(0, 0);
      } else if (this.props.selectedTab == 1) {
        window.scrollTo(0, 300);
      }
    }
  }

  fetchCourseContentData = () => {
    let currentFolderDetails = sessionStorage.getItem("currentFolderDetails");
    if (currentFolderDetails) {
      currentFolderDetails = JSON.parse(currentFolderDetails);
      this.setState(
        {
          pushNavFolder: [{ ...currentFolderDetails }],
        },
        () => {
          this.props.navFolderPushed(this.state.pushNavFolder);
          this.fetchCourseApiCall();
        }
      );
    } else {
      this.fetchCourseApiCall();
    }
  };
  // let code = window.location.href.match(new RegExp('[^/]+(?=/$|$)'))[0];

  // if(currentFolderDetails) {
  //   this.setState({
  //     pushNavFolder: [{...currentFolderDetails}]
  //   })
  // }
  fetchCourseApiCall = () => {
    const promoHeaders = {
      "Api-Version": 11,
    };

    let tokenCode;
    let code1 = window.location.href.match(new RegExp("[^/]+(?=/$|$)"))[0];

    if (code1.includes("token")) {
      tokenCode = decodeURIComponent(getQuery()["token"]);
    } else {
      let token = sessionStorage.getItem("token");
      if (token) {
        tokenCode = token;
      } else {
        tokenCode = code1;
      }
    }

    this.token = tokenCode;
    const nestedFolderLen = this.state.pushNavFolder.length;

    let folderId = nestedFolderLen
      ? this.state.pushNavFolder[nestedFolderLen - 1].id
      : 0;

    axios
      .get(
        `${AppConfig.BASE_URL}/v2/course/preview/content/${tokenCode}?folderId=${folderId}`,
        { headers: promoHeaders }
      )
      .then((response) => {
        if (response.data.data) {
          this.setState({
            ContentData: response.data.data,
          });
          sessionStorage.removeItem("currentFolderDetails");
        }
      })
      .catch((error) => {
        // console.log(error);
        this.setState({ loading: false, error: "Internal server error" });
        sessionStorage.removeItem("currentFolderDetails");
      });
  };

  handleLockedContentClick = () => {
    this.setState({ isContentLocked: true });
  };

  handleFolderClick = (item) => {
    let folderDetails = { id: item.id, name: item.name };

    if (this.props.viaMobileOverview) {
      this.props.history.push("/Preview");
      sessionStorage.setItem(
        "currentFolderDetails",
        JSON.stringify(folderDetails)
      );
    } else if (this.props.viaDesktopOverview) {
      this.props.handleTabClick(1);
      sessionStorage.setItem(
        "currentFolderDetails",
        JSON.stringify(folderDetails)
      );
    } else {
      this.setState(
        {
          isFolderClicked: true,
          pushNavFolder: [
            ...this.state.pushNavFolder,
            { id: item.id, name: item.name },
          ],
        },
        () => {
          this.props.navFolderPushed(this.state.pushNavFolder);
          this.fetchCourseContentData();
        }
      );
    }

    // this.pushNavFolder.push({ id: item.id, name: item.name });
  };
  handleIframOpen = (content, jwplayerVideo) => {
    if (content.videoType == "jw-hosted") {
      if (content.isLocked == 1 || content.isWebVideoAllowed == 0) {
        // alert("Please purchase to see this video");
        // this.setState({
        //   modalIframeOpen: true,
        //   modalIframeId: content.id,
        //   jwplayerVideo: jwplayerVideo,
        // });
      } else {
        this.setState({
          modalIframeOpen: true,
          modalIframeId: content.id,
          jwplayerVideo: jwplayerVideo,
        });
      }
    } else {
      this.handleOpenAppModal();
    }
  };

  handleOpenAppModal = () => {
    this.setState({
      openAppModal: true,
    });
  };

  getContentRestrictLayer = () => {
    return (
      <Modal
        size="small"
        open={this.state.openAppModal}
        onClose={this.handleCloseAppModal}
        closeOnDimmerClick={false}
      >
        <Modal.Content style={{ textAlign: "center" }}>
          <img
            src={SVGClose}
            alt="Close"
            className="addContent__SVGClose"
            onClick={() => {
              this.handleCloseAppModal();
            }}
          />
          <img
            style={{ marginTop: "3rem" }}
            src={ContentRestrict}
            alt="ContentRestrict"
          />
          <h3>This content can not be viewed on the web</h3>
          <p>
            {!this.state.student
              ? "Preview restricted on web! We value your content privacy. Find this course on your mobile app to view"
              : "View this content on your mobile app."}
          </p>
        </Modal.Content>
      </Modal>
    );
  };

  handleCloseAppModal = () => {
    this.setState({
      openAppModal: false,
    });
  };

  handleIframeClose = () => {
    this.setState({ modalIframeOpen: false, modalIframeId: null });
  };

  openDocImage = (content) => {
    if (content.url) {
      window.open(getS3DownloadUrl(content.url, this.token));
    }
  };

  handleOnClick = (item) => {
    const itemType = item.contentType;
    switch (itemType) {
      case RESOURCE_TYPES.folder:
        this.handleFolderClick(item);
        break;

      case RESOURCE_TYPES.video:
        this.props.onVideoClick();
        break;

      case RESOURCE_TYPES.test:
        break;

      case RESOURCE_TYPES.image:
        break;

      case RESOURCE_TYPES.batch:
        this.props.onBatchClick(item.batch);
        break;
    }
  };

  showDoc = (content) => {
    return (
      <div className="addContent__content--video forHover">
        {/* <a href={content.url} download="classplus" target="_blank" className="anchorColor w80" > */}
        <div
          className="flexrow"
          style={{ alignItems: "center" }}
          onClick={() => this.openDocImage(content)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100px",
              // width: "80px",
              flexShrink: 0,
              position: "relative",
            }}
          >
            <img
              src={this.getIconImage(content)}
              alt="image"
              width="45px"
              height="45px"
              className="storeDocImage"
            />
            {content.isLocked ? (
              <img src={NewLockPurchase} alt="image" className="lockdoc" />
            ) : (
              ""
            )}
          </div>
          <div className="addContent__content--details">
            <p className="addContent__content--details--heading">
              {stringUtils.getShortenedSubstring(content.name, 40)}
            </p>
            <p className="addContent__content--details--content">
              {content.description}
            </p>
          </div>
        </div>
      </div>
    );
  };

  getIconImage(item) {
    const itemType = item.contentType;
    let icon = null;
    switch (true) {
      case RESOURCE_TYPES.video == itemType:
        icon = item.thumbnailUrl;
        break;
      case RESOURCE_TYPES.document == itemType &&
        RESOURCE_FILETYPES[RESOURCE_TYPES.document].ext.indexOf(
          get(item, "format", "pdf")
        ) != -1:
        icon = SVGPdf;
        break;
      case RESOURCE_TYPES.document == itemType:
        icon =
          // (item.url && getS3DownloadUrl(item.url, this.token)) ||
          SVGPdf;

        break;
      case RESOURCE_TYPES.test == itemType:
        icon = SVGTest;
        break;
      default:
        icon = SVGDefaultVideo;
    }
    return icon;
  }

  showVideoOrImage = (content) => {
    const { modalIframeOpen, modalIframeId } = this.state;

    const regex = new RegExp(
      "(?:(?:\\.be\\/|embed\\/|v\\/|\\?v=|\\&v=|\\/videos\\/)|(?:[\\w+]+#\\w\\/\\w(?:\\/[\\w]+)?\\/\\w\\/))([\\w-_]+)"
    );
    const vId =
      content.url && content.url.match(regex) && content.url.match(regex)[1];

    return (
      <div className="addContent__content--video  forHover">
        <div className="flexrow maxwidth85">
          <div
            className="IframePlay"
            onClick={() => this.handleIframOpen(content)}
          >
            {content.duration && (
              <div className="videoTime">{content.duration}</div>
            )}
            <img
              src={content.isLocked ? NewLockPurchase : SVGIframe}
              alt="iframeplay"
              className="IframePlaySvg"
            />
            <img
              src={this.getIconImage(content)}
              alt="video"
              width="120px"
              height="90px"
              className="storeVideoImage"
            />
          </div>

          {!this.state.jwplayerVideo && modalIframeId === content.id && (
            <div className="iframeContent">
              <iframe
                src={`https://www.youtube.com/embed/${vId}`}
                frameBorder="0"
                allowFullScreen
                width="750px"
                height="500px"
                style={{ margin: "0 auto" }}
              ></iframe>

              <Icon
                size="large"
                className="float-right"
                name="close"
                style={{
                  marginLeft: "1rem",
                  cursor: "pointer",
                  marginTop: "-30px",
                }}
                onClick={this.handleIframeClose}
              />
            </div>
          )}
          <div className="addContent__content--details">
            <p className="addContent__content--details--heading">
              {stringUtils.getShortenedSubstring(
                content.name.replace(
                  /\.(mov|avi|wmv|flv|3gp|mp4|mpg|mkv|mp3)$/,
                  ""
                ),
                40
              )}
            </p>
            <p className="addContent__content--details--content">
              {content.description}
            </p>
          </div>
        </div>
      </div>
    );
  };

  showFolder = (content) => {
    const { videos, files, tests } = content.resources || {};
    const numberOfContent = () => {
      if (!videos && !files && !tests) {
        return (
          <p
            className="addContent__content--details--content--item"
            style={{ marginTop: "0.4rem" }}
          >
            {"No Content inside"}
          </p>
        );
      } else {
        return (
          <React.Fragment>
            <p className="addContent__content--details--content">
              {videos != 0 && (
                <span className="addContent__content--details--content--item">
                  {" "}
                  {content.resources.videos} video(s)
                  {files || tests ? ", " : ""}
                </span>
              )}
              {files != 0 && (
                <span className="addContent__content--details--content--item">
                  {" "}
                  {content.resources.files} file(s){tests ? " ," : ""}
                </span>
              )}
              {tests != 0 && (
                <span className="addContent__content--details--content--item">
                  {" "}
                  {content.resources.tests} test(s){" "}
                </span>
              )}
            </p>
            {<FreeContent item={content} />}
          </React.Fragment>
        );
      }
    };

    return (
      <div
        // onClick={() => this.handleOnClick(content)}
        className="addContent__content--folder  forHover"
      >
        <div className="flexrow maxwidth85" style={{ alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // height: "75px",
              width: "100px",
              // height: "45px",
              // width: "80px",
              flexShrink: 0,
            }}
          >
            <img src={folderlarge} alt="Folder" />
          </div>
          <div
            className="addContent__content--details  "
            // style={{ padding: "1.3rem 0" }}
          >
            <p className="addContent__content--details--heading">
              {stringUtils.getShortenedSubstring(content.name, 40)}
            </p>
            {numberOfContent()}
          </div>
        </div>
        <img src={forward} alt="" />
      </div>
    );
  };

  getItemDiv = (item) => {
    const type = item.contentType;
    switch (type) {
      case RESOURCE_TYPES.folder:
        return this.showFolder(item);
        break;

      case RESOURCE_TYPES.image:
      case RESOURCE_TYPES.video:
        if (item.videoType == "youtube-hosted") {
          return this.showVideoOrImage(item);
        } else if (item.videoType == "jw-hosted") {
          return this.showPlayer(item);
        } else return "";
        break;

      case RESOURCE_TYPES.document:
        return this.showDoc(item);
        break;

      case RESOURCE_TYPES.test:
        //  return "";
        return this.showTest(item);
        break;
    }
  };

  showPlayer = (item) => {
    const { modalIframeId, modalIframeOpen } = this.state;

    return (
      <div
        className={
          this.exhaustCondition(item)
            ? "addContent__content--video forHover opacityFade"
            : "addContent__content--video forHover"
        }
        style={{ position: "relative" }}
      >
        <div className="flexrow" style={{ alignItems: "center" }}>
          <div className="IframePlay">
            <img
              src={item.thumbnailUrl || SVGDefaultVideo}
              alt="video"
              width="100px"
              height="75px"
              className="storeVideoImage"
              onClick={() => this.handleIframOpen(item, true)}
            />
            <div
              className="videoTime"
              onClick={() => this.handleIframOpen(item, true)}
            >
              {item.duration}
            </div>
            <img
              className="IframePlaySvg"
              src={item.isLocked ? NewLockPurchase : SVGIframe}
              onClick={() => this.handleIframOpen(item, true)}
            />
          </div>

          {
            // 	  <Modal
            //     open={modalIframeId === item.id ? modalIframeOpen : false}
            //     basic
            //   >
            //     <Modal.Content
            //       style={{
            //         display: "flex",
            //         justifyContent: "center",
            //         alignItems: "flex-start",
            //       }}
            // 	>
          }

          {this.state.jwplayerVideo && modalIframeId === item.id && (
            <div className="iframeContent">
              <div style={{ position: "relative" }}>
                <Player
                  item={item}
                  handleOpenWatchExhaustedModal={
                    this.handleOpenWatchExhaustedModal
                  }
                  courseId={this.props.courseId}
                  handleIframeClose={this.handleIframeClose}
                  student={this.state.student}
                  getFolderContent={this.getFolderContent}
                  isExhaustedDisabled={true}
                />
                <img
                  src={SVGClose}
                  className={classes.videoCrossImg}
                  // style={{ position: "absolute", right: "10%", top: "30%" }}
                  onClick={() => {
                    this.handleIframeClose(true);
                  }}
                />

                <Backdrop
                  show={this.state.jwplayerVideo && modalIframeId === item.id}
                  toggle={this.handleIframeClose}
                  backgroundColor={false}
                />
              </div>
            </div>
          )}

          {
            // 	    </Modal.Content>
            //   </Modal>
          }
          <div className="addContent__content--details">
            <p className="addContent__content--details--heading">
              {stringUtils.getShortenedSubstring(
                item.name.replace(
                  /\.(mov|avi|wmv|flv|3gp|mp4|mpg|mp3|mkv)$/,
                  ""
                ),
                80
              )}
            </p>

            <p className="addContent__content--details--content">
              {item.description}
            </p>
            <RestrictionDetails item={item} />
          </div>
        </div>

        {item.isPreview == 1 && (
          <div className="addContent__content--actions">
            {/*<div className="lockContainer">
              <p className="greytext2">
                <img
                  src={item.isLocked ? SVGlocked : SVGunlocked}
                  alt="locked"
                />{" "}
                {item.isLocked ? "Locked" : "Unlocked"}{" "}
              </p>
              
            </div>*/}
          </div>
        )}
      </div>
    );
  };

  exhaustCondition = (item) => {
    if (
      item.isVideoRestricted == 1 &&
      (item.videoCountAvailable == 0 || item.videoDurationAvailable == 0)
    )
      return true;
    else return false;
  };

  handleScheduledLockContentOpen = () => {
    this.setState({
      scheduledLockContent: true,
    });
  };

  handleScheduledLockContentClose = () => {
    this.setState({
      scheduledLockContent: false,
    });
  };

  showTest = (content) => {
    return (
      <div style={{ position: "relative" }}>
        {content.isScheduled ? (
          <div
            className="addContent__content--video"
            onClick={() => this.handleScheduledLockContentOpen()}
            style={{
              position: "absolute",
              display: "block",
              backgroundColor: "rgba(251, 251, 251, 0.9)",
              zIndex: "1",
              cursor: "pointer",
              width: "100%",
              height: "100%",
              marginTop: "2px",
              borderBottom: "none",
            }}
          >
            <div style={{ display: "flex" }}>
              <img src={LockPurchase} style={{ margin: "24px" }} alt="lock" />
              <div>
                <div style={{ fontSize: "18px", margin: "27px 61px 8px 36px" }}>
                  Content locked by your teacher
                </div>
                <div style={{ fontSize: "14px" }}>
                  {content.scheduledMessage}{" "}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className="addContent__content--video forHover"
          style={{ position: "relative" }}
        >
          <a
            href={content.url}
            download="classplus"
            target="_blank"
            className="anchorColor w80"
          >
            <div className="flexrow" style={{ alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100px",
                  // width: "80px",
                  flexShrink: 0,
                }}
              >
                <img
                  src={this.getIconImage(content)}
                  alt="File"
                  width="45px"
                  height="45px"
                  // style={{ marginLeft: "1.3rem" }}
                />
                {content.isLocked ? (
                  <img src={NewLockPurchase} alt="image" className="lockdoc" />
                ) : (
                  ""
                )}
              </div>
              <div
                className="addContent__content--details"
                // style={{ padding: "1.3rem 0" }}
              >
                <p className="addContent__content--details--heading">
                  {stringUtils.getShortenedSubstring(content.name, 40)}
                </p>

                <div
                  className="addContent__content--buttons flexrow"
                  style={{
                    marginTop: content.isScheduled ? "0rem" : "1rem",
                  }}
                ></div>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  };

  onBackNavigation = () => {
    let a = [...this.state.pushNavFolder];
    a.pop();
    this.setState(
      {
        pushNavFolder: a,
      },
      () => {
        this.props.navFolderPushed(this.state.pushNavFolder);
        this.fetchCourseContentData();
      }
    );
  };

  render() {
    let {
      ContentData,
      isContentLocked,
      isFolderClicked,
      pushNavFolder,
      isViewClicked,
      freeContent,
    } = this.state;
    let { priceDetails, stateArray, data } = this.props;

    let ContentDataSlice =
      window.location.href.match("/Preview") || this.props.selectedTab == 1
        ? ContentData
        : ContentData.slice(0, 3);

    return (
      <React.Fragment>
        {/* {window.location.href.match("/Preview") ? (
          <div className={classes.header}>
            <button
              className={classes.backBtn}
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              <img src={back} alt="" style={{ marginRight: "24px" }} />
            </button>
            Course Contents
          </div>
        ) : null} */}
        <div
          className={classes.ContentList}
          style={
            window.location.href.match("/Preview")
              ? { marginBottom: "80px" }
              : {}
          }
        >
          {pushNavFolder.length >= 1 ? (
            <div
              className={classes.InsideFolderHeader}
              onClick={this.onBackNavigation}
            >
              <img
                src={back}
                alt=""
                style={{ marginRight: "8px" }}
                // onClick={this.onBackNavigation}
              />
              <div
                className={classes.cheatBorder}
                style={{
                  width: window.innerWidth < 1024 ? "109%" : "100%",
                  left: window.innerWidth < 1024 ? "-5%" : "0%",
                }}
              ></div>

              {pushNavFolder[pushNavFolder.length - 1].name}
            </div>
          ) : (
            <div className={classes.ContentListHeading}>
              Content{" "}
              {/* <div
                className={classes.cheatBorder}
                style={{
                  width: window.innerWidth < 1024 ? "109%" : "100%",
                  left: window.innerWidth < 1024 ? "-5%" : "0%",
                }}
              ></div> */}
            </div>
          )}
          <div className={classes.ContentListings}>
            {ContentDataSlice && ContentDataSlice.length > 0
              ? ContentDataSlice.map((item, index) => {
                  return (
                    <div
                      className={classes.ContentListItem}
                      style={{
                        borderBottom:
                          index + 1 == ContentDataSlice.length
                            ? "none"
                            : "1px solid #E5E5E5",
                      }}
                      key={index}
                      onClick={
                        //TODO CHECK THIS CONDITION
                        item.isLocked
                          ? this.handleLockedContentClick
                          : item.contentType == 1
                          ? () => {
                              this.handleFolderClick(item);
                            }
                          : () => {}
                      }
                    >
                      {this.getItemDiv(item)}
                      {/* <div className={classes.ContentListItemImg}>
									<img src={contentData.image} alt="" />
								</div>
								<div className={classes.ContentListItemContent}>
									<div className={classes.ContentListItemContentRow1}>
										<div>{contentData.name}</div>
										<div className={classes.ContentListItemContentRow1PreviewTag} style={contentData.isPreview ? { display: "flex" } : { display: "none" }}>
											<img src={playIcon} alt="" style={{ marginRight: "4px" }} />
											Preview
										</div>

									</div>
									<div className={classes.ContentListItemContentRow2}>
										<img src={clock} alt="" />
										<div className={classes.ContentListItemContentRow2Text}>
											{contentData.subText} 2 video(s), 2 file(s)
										</div>
									</div>
								</div> */}
                    </div>
                  );
                })
              : ""}
          </div>
          <ContentLockedModal
            isCloseFormModal={this.props.isCloseFormModal}
            closeFormModal={this.props.closeFormModal}
            isContentLocked={isContentLocked}
            handleContentLockedModal={this.handleContentLockedModal}
            priceDetails={priceDetails}
            stateArray={stateArray}
            data={data}
            toggleBothModals={this.props.toggleBothModals}
            getUpdatedCouponsAndPriceDetails={
              this.props.getUpdatedCouponsAndPriceDetails
            }
            toggleCouponModalFromHome={this.props.toggleCouponModalFromHome}
            removeCouponApplied={this.props.removeCouponApplied}
            handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
            openFormModal={this.props.openFormModal}
            currentCouponCode={this.props.currentCouponCode}
            multipleCouponsCondition={this.props.multipleCouponsCondition}
          />
          <div
            className={classes.ContentListFooter}
            style={{
              borderTop: this.props.isInsideContent
                ? null
                : this.props.selectedTab
                ? null
                : "1px dashed #CCCCCC",
            }}
          >
            {this.props.isInsideContent ? null : (
              <button
                className={classes.ContentListBtn}
                onClick={() => {
                  // this.setState({ isViewClicked: true });
                  window.screen.width < 1000
                    ? this.props.history.push("/Preview")
                    : this.props.handleViewAllPreviewContent(true);
                }}
              >
                View all content
              </button>
            )}
          </div>
        </div>
        {
          // this.state.openAppModal && this.getContentRestrictLayer()
        }
      </React.Fragment>
    );
  }
}

export default withRouter(ContentList);
