import React from "react";
import PropTypes from "prop-types";
import classes from "./Backdrop.module.css";

const backdrop = (props) => {
  return props.show ? (
    <div
      className={classes.Backdrop}
      onClick={props.toggle}
      style={{
        backgroundColor: props.backgroundColor
          ? "rgba(0,0,0,0.5)"
          : "transparent",
      }}
    ></div>
  ) : null;
};

backdrop.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
};
export default backdrop;
