import React, { Component } from "react";
import CouponsModal from "./CouponsModal";
import "./Coupons.css";
import Form from "../../Form/Form";
import ScrollButton from "../../images/ScrollButton.svg";
import { getCourseCouponListStudent } from "../../graphApis";
import { executeQuery } from "../../graphApis/graph";

export class Coupons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableCoupons: ["Flat 800", "Bazinga", "Classplus"],
      isCloseCouponModal: true,
      isHideCouponDetails:
        this.props && this.props.isHideCouponDetails != undefined
          ? this.props && this.props.isHideCouponDetails
          : true,
      isCloseFormModal: true,
      isFormDetails: false,
      showCouponsOnForm: false,
      currentCouponCode: {},
      modalStudentList: [],
      limit: 20,
      offset: 0,
      couponHasMore: false,
      loadingCoupons: true,
      dataFetching: false,
      inputCouponCode: "",
    };
    this.infiniteView = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps &&
      this.props &&
      this.props.studentCouponList != prevProps.studentCouponList
    ) {
      if (
        this.props.studentCouponList &&
        this.props.studentCouponList.length > 0
      ) {
        sessionStorage.setItem("showCouponsOnForm", true);
        this.setState({
          modalStudentList: [...(this.props && this.props.studentCouponList)],
        });
      }
    }

    if (
      prevProps &&
      this.props &&
      prevProps.isHideCouponDetails != this.props.isHideCouponDetails
    ) {
      this.setState({
        isHideCouponDetails: this.props.isHideCouponDetails,
      });
    }
  }

  componentDidMount() {
    this.getCouponsList();
  }

  componentWillUnmount() {
    this.setState({
      offset: 0,
    });
  }

  toggleCouponModal = (offset2) => {
    this.setState(
      {
        isHideCouponDetails: !this.state.isHideCouponDetails,
      },
      () => {
        if (offset2 == 0 || this.state.offset == undefined) {
          this.setState(
            {
              offset: 0,
            },
            () => {
              this.getCouponsList();
            }
          );
        } else {
          this.getCouponsList();
        }

        this.props.toggleCouponModalFromHome(true);
      }
    );
  };

  getCouponsList = (offset2) => {
    let token = sessionStorage.getItem("token");
    let { limit, offset, couponHasMore } = this.state;

    if (offset2 == 0) {
      offset = offset2;
    }
    executeQuery(getCourseCouponListStudent, {
      token,
      limit,
      offset,
    })
      .then((data) => {
        let resCoupons = data.data.data.coupons;
        let resText = data.data.data.label.text;
        this.setState({
          modalStudentList:
            offset == 0
              ? resCoupons
              : [...this.state.modalStudentList, ...resCoupons],
          offset: offset + limit,
          couponHasMore: resCoupons.length > limit - 1 ? true : false,
          dataFetching: false,
          loadingCoupons: false,
        });
      })
      .catch((err) => {
        this.setState({
          loadingCoupons: false,
        });
        console.error(err, "****GraphError");
        console.error(err.message);
      });
  };

  toggleFormModal = () => {
    this.setState({
      isFormDetails: !this.state.isFormDetails,
      isCloseFormModal: this.state.isFormDetails,
    });
  };

  toggleBothModals = () => {
    this.toggleCouponModal();
    this.toggleFormModal();
  };

  handleCouponApplied = (code, source) => {
    let token = sessionStorage.getItem("token");

    this.props
      .getUpdatedCouponsAndPriceDetails(token, code.code, "couponApply")
      .then((res) => {
        sessionStorage.setItem("currentCouponCode", JSON.stringify(code));
        this.setState(
          {
            currentCouponCode: code,
          },
          () => {
            if (source == "mainList") {
              this.props.closeFormModal();
              this.props.setCouponAppliedSuccess();
            } else {
              this.props.openFormModal();
              this.props.toggleCouponModalFromHome(true);
              this.props.setCouponAppliedSuccess();
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });

    // this.setState(
    //   {
    //     currentCouponCode: code,
    //   },
    //   () => {
    //     // this.props.getUpdatedCouponsAndPriceDetails(
    //     //   token,
    //     //   code.code,
    //     //   "couponApply"
    //     // );
    //     if (source == "mainList") {
    //       this.props.closeFormModal();
    //       // this.props.setCouponAppliedSuccess();
    //     } else {
    //       this.props.openFormModal();
    //       this.props.toggleCouponModalFromHome(true);
    //       // this.props.setCouponAppliedSuccess();
    //     }
    //   }
    // );
  };

  removeCouponApplied = (code) => {
    let token = sessionStorage.getItem("token");

    this.props
      .getUpdatedCouponsAndPriceDetails(token, "", "couponRemove")
      .then((res) => {
        this.setState(
          {
            currentCouponCode: {},
          },
          () => {
            sessionStorage.setItem("currentCouponCode", JSON.stringify({}));
          }
        );
      })
      .catch((err) => console.error(err));
  };

  // scrollFunc = () => {
  //   var elmnt = document.getElementById("couponBar");
  //   elmnt.scrollLeft += 30;
  //   elmnt.scroll(elmnt.scrollLeft, 0);
  // };

  rightScrollClick = () => {
    this.infiniteView.current.scrollBy(150, 0);
    this.setscrollLeft(this.infiniteView.current.scrollLeft);
  };

  leftScrollClick = () => {
    this.infiniteView.current.scrollBy(-150, 0);
    this.setscrollLeft(this.infiniteView.current.scrollLeft);
  };

  setscrollLeft = (position) => {
    this.setState({
      scrollLeft: position,
    });
  };

  handleFormDetails = () => {
    this.setState({ isFormDetails: false });
  };

  changeCouponCodeValue = (e) => {
    this.setState({
      inputCouponCode: e.target.value,
    });
  };

  render() {
    const {
      availableCoupons,
      isCloseFormModal,
      isFormDetails,
      currentCouponCode,
      showCouponsOnForm,
      scrollLeft,
      modalStudentList,
      loadingCoupons,
      limit,
      offset,
      couponHasMore,
    } = this.state;
    const {
      studentCouponList,
      stateArray,
      completePageData,
      priceDetails,
      toggleBothModals,
      toggleCouponFromHome,
      haveInvisibleCoupon,
      multipleCouponsCondition,
    } = this.props;

    return multipleCouponsCondition !== 3 ? (
      <div className="couponsDiv">
        <div
          style={{
            visibility: this.props.notToShowCoupons ? "hidden" : "visible",
          }}
        >
          <div className="CouponTop">
            <div className="Label">Buy using these Coupons</div>
            {window.innerWidth > 800 ? (
              ""
            ) : studentCouponList && studentCouponList.length > 4 ? (
              <button
                className="viewALLBtnMobile"
                onClick={() => {
                  this.setState(
                    {
                      offset: 0,
                    },
                    () => {
                      this.props.handleOverviewBtnClicked(true);
                      // this.props.closeFormModal(true);
                      this.props.toggleCouponModalFromHome();
                    }
                  );
                }}
              >
                View All
              </button>
            ) : (
              ""
            )}
          </div>
          {scrollLeft > 0 ? (
            <div
              className="Scrollbutton"
              style={{ left: "-30px", right: "0px", width: "60px" }}
              onClick={() => this.leftScrollClick()}
            >
              <img
                src={ScrollButton}
                alt=""
                style={{ transform: "rotate(-180deg)" }}
              />
            </div>
          ) : (
            ""
          )}
          <div className="CouponBottom" id="couponBar" ref={this.infiniteView}>
            {studentCouponList &&
              studentCouponList.map((couponList, i) => {
                if (i <= 7) {
                  return (
                    <div
                      key={i}
                      className="coupon"
                      onClick={() => {
                        this.handleCouponApplied(
                          couponList && couponList.coupon,
                          "mainList"
                        );
                        this.props.handleOverviewBtnClicked(true);
                      }}
                    >
                      <div className="couponLabel">
                        {couponList &&
                          couponList.coupon &&
                          couponList.coupon.label}
                      </div>
                      <div className="couponName">
                        {couponList &&
                          couponList.coupon &&
                          couponList.coupon.name}
                      </div>
                    </div>
                  );
                }
              })}
            {
              //   studentCouponList && studentCouponList.length > 4 ? (
              //   <div
              //     className="coupon"
              //     id="ViewAll"
              //     onClick={() => this.toggleCouponModal()}
              //   >
              //     <div className="couponLabel">View All</div>
              //     <div className="couponName">See more coupons</div>
              //   </div>
              // ) : (
              //   ""
              // )
            }
          </div>

          {studentCouponList && studentCouponList.length > 4 ? (
            <div
              className="Scrollbutton"
              onClick={() => this.rightScrollClick()}
            >
              <img src={ScrollButton} alt="" />
            </div>
          ) : (
            ""
          )}

          {studentCouponList && studentCouponList.length >= 8 ? (
            <div className="CouponWebBtn">
              {window.innerWidth < 800 ? (
                ""
              ) : (
                <button
                  className="viewALLBtnDesktop"
                  onClick={() => {
                    this.props.handleOverviewBtnClicked(true);
                    // this.props.closeFormModal(true);
                    this.props.toggleCouponModalFromHome();
                  }}
                >
                  View All Coupons
                </button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <CouponsModal
          isCloseFormModal={this.props.isCloseFormModal}
          isHideCouponDetails={this.state.isHideCouponDetails}
          toggleCouponModal={this.toggleCouponModal}
          studentCouponList={modalStudentList}
          handleCouponApplied={this.handleCouponApplied}
          currentCouponCode={currentCouponCode}
          removeCouponApplied={this.removeCouponApplied}
          toggleBothModals={this.props.toggleBothModals}
          toggleCouponFromHome={this.props.toggleCouponFromHome}
          loadingCoupons={loadingCoupons}
          limit={limit}
          offset={offset}
          hasMore={couponHasMore}
          getCouponsList={this.getCouponsList}
          handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
          haveInvisibleCoupon={haveInvisibleCoupon}
          multipleCouponsCondition={multipleCouponsCondition}
        />

        {/* {isFormDetails ? (
          <Form
            isCloseFormModal={isCloseFormModal}
            closeFormModal={this.toggleFormModal}
            stateArray={stateArray}
            isFormDetails={isFormDetails}
            handleFormDetails={this.handleFormDetails}
            data={completePageData}
            priceDetails={priceDetails}
            handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
            handleCouponApplied={this.handleCouponApplied}
            currentCouponCode={currentCouponCode}
            removeCouponApplied={this.removeCouponApplied}
            showCouponsOnForm={showCouponsOnForm}
            toggleBothModals={this.props.toggleBothModals}
            
            toggleCouponModalFromHome={this.props.toggleCouponModalFromHome}
            formOpenViaCoupons={true}
            studentCouponList={studentCouponList}
          />
        ) : (
          ""
        )} */}
      </div>
    ) : (
      <div className="couponsDiv">
        <div
          style={{
            visibility: this.props.notToShowCoupons ? "hidden" : "visible",
          }}
        >
          <div className="CouponTop">
            <div className="Label">Have a coupon code? Apply here</div>
          </div>

          <div className="CouponBottom" id="couponBar" ref={this.infiniteView}>
            <div className="enterMainCouponWrapper">
              <div className="enterMainCoupon">
                <input
                  className="enterMainCouponField"
                  type="text"
                  value={this.state.inputCouponCode}
                  onChange={(e) => {
                    this.changeCouponCodeValue(e);
                  }}
                  placeholder="Enter a coupon code"
                />
                <button
                  disabled={
                    this.state.inputCouponCode.length > 3 ? false : true
                  }
                  style={{
                    color:
                      this.state.inputCouponCode.length > 3
                        ? "#009AE0"
                        : "#999999",
                  }}
                  className="enterMainCouponButton"
                  onClick={() => {
                    this.handleCouponApplied({
                      code: this.state.inputCouponCode,
                    });
                  }}
                >
                  Buy using this
                </button>
              </div>
            </div>
          </div>
        </div>

        <CouponsModal
          isCloseFormModal={this.props.isCloseFormModal}
          isHideCouponDetails={this.state.isHideCouponDetails}
          toggleCouponModal={this.toggleCouponModal}
          studentCouponList={modalStudentList}
          handleCouponApplied={this.handleCouponApplied}
          currentCouponCode={currentCouponCode}
          removeCouponApplied={this.removeCouponApplied}
          toggleBothModals={this.props.toggleBothModals}
          toggleCouponFromHome={this.props.toggleCouponFromHome}
          loadingCoupons={loadingCoupons}
          limit={limit}
          offset={offset}
          hasMore={couponHasMore}
          getCouponsList={this.getCouponsList}
          handleOverviewBtnClicked={this.props.handleOverviewBtnClicked}
          haveInvisibleCoupon={haveInvisibleCoupon}
          multipleCouponsCondition={multipleCouponsCondition}
        />
      </div>
    );
  }
}

export default Coupons;
