import React, { Component } from 'react';
import Accordian from '../../UI/Accordian/Accordian';
import classes from './Description.module.css';
class Description extends Component {
	constructor(props) {
		super(props);
		this.state = {
			description:
				'Securing a Bank Job and getting placed in the Banking sector has been one of the most sought after career streams for more than a decade now. Lakhs of aspirants appear for Bank Exams like IBPS PO, IBPS Clerk, IBPS RRB, SBI PO, RBI Grade etc. to get placed in one of the reputed banks of the country every year.These bank exams are conducted at national level throughout the year. Candidates who aspire for a banking career must be aware about all the Banking exams and start preparing. Here’s the exhaustive list of all the upcoming bank exams 2019Securing a Bank Job and getting placed in the Banking sector has been one of the most sought after career streams for more than a decade now. Lakhs of aspirants appear for Bank Exams like IBPS PO, IBPS Clerk, IBPS RRB, SBI PO, RBI Grade etc. to get placed in one of the reputed banks of the country every year.These bank exams are conducted at national level throughout the year. Candidates who aspire for a banking career must be aware about all the Banking exams and start preparing. Here’s the exhaustive list of all the upcoming bank exams 2019'
		};
	}

	render() {
		let { description } = this.props;
		return (
			<div className={classes.container}>
				<p className={classes.heading}>Description</p>
				<Accordian description={description} />
			</div>
		);
	}
}

export default Description;
